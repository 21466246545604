import './SideNavSection.scss';

import React, { ReactElement } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const SideNavSection = (): ReactElement => {
  // TODO 본인의 번호를 닉네임으로 변경하세요
  const nickName = [
    'Liu',
    'Kevin',
    'Milly',
    'Mai',
    'Woni',
    'Jjinny',
    'Ray',
    'Bob',
    'Karl',
    'YG',
    'Jay',
    'Cindy',
    'Ezreal',
    'Estell',
    'Dorothy',
    'Juni',
    '17',
  ];

  return (
    <div id="SideNavSection">
      <Nav className="flex-column">
        <NavLink to="/board" className="nav-link">
          나도한마디
        </NavLink>
        {nickName.map((value, index) => {
          return (
            <NavLink to={'/board-' + (index + 1)} className="nav-link" key={value}>
              {nickName[index]}의 나도한마디
            </NavLink>
          );
        })}
        <NavLink key="event" to="/event" className="nav-link">
          경조사
        </NavLink>
      </Nav>
    </div>
  );
};

export default SideNavSection;
