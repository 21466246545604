import React, { ReactElement } from 'react';
import { Route } from 'react-router-dom';

import BoardDetailContainer from './BoardDetailContainer';
import BoardListContainer from './BoardListContainer';

const BoardPage = (): ReactElement => {
  return (
    <>
      <Route exact path="/board" component={BoardListContainer}></Route>
      <Route exact path="/board/detail/:id" component={BoardDetailContainer}></Route>
    </>
  );
};

export default BoardPage;
