import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import { AuthContext } from '../../../App';
import { CreateUpdateRequest, RequestInfo } from '../../../service/work/model/Work';
import WorkService from '../../../service/work/WorkService';

const RequestContainer = () => {
  const history = useHistory();
  const workService = new WorkService();
  const { id } = useParams<{ id: string }>();
  const requestId = Number(id);
  const { state } = useContext(AuthContext);

  const [request, setRequest] = useState<RequestInfo>();
  const [team, setTeam] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    if (requestId) {
      void workService.getRequestDetail(requestId).then((response) => {
        if (response.successOrNot === 'Y') {
          setRequest(response.data);
          setTeam(response.data.requesterTeam);
          setName(response.data.requesterTeam);
          setTitle(response.data.requestTitle);
          setContent(response.data.requestContent);
        } else {
          alert('요청 건에 대한 내역 조회중 네트워크 장애가 발생하였습니다.\n잠시후 다시 시도해 주세요.');
        }
      });
    }
  }, []); // eslint-disable-lint

  const handleSaveBtnOnclick = () => {
    if (requestId) {
      const req: CreateUpdateRequest = {
        requestId: requestId,
        requesterTeam: team,
        requesterName: name,
        requestTitle: title,
        requestContent: content,
      };
      void workService.updateRequest(req).then((response) => {
        if (response.successOrNot === 'Y') {
          history.push('/request/detail/' + requestId);
        } else {
          alert('요청 건에 대한 수정작업 처리중 네트워크 장애가 발생하였습니다.\n잠시후 다시 시도해 주세요.');
        }
      });
    } else {
      const req: CreateUpdateRequest = {
        requesterTeam: team,
        requesterName: name,
        requestTitle: title,
        companyId: state.work.companyId,
        requestContent: content,
      };
      void workService.createRequest(req).then((response) => {
        if (response.successOrNot === 'Y') {
          history.push('/request');
        } else {
          alert('요청 건에 대한 등록작업 처리중 네트워크 장애가 발생하였습니다.\n잠시후 다시 시도해 주세요.');
        }
      });
    }
  };

  return (
    <div id="RequestContainer">
      {(!requestId || (requestId && request)) && (
        <div>
          <Row>
            <Form id="requestForm" style={{ width: '100%' }}>
              <Form.Row>
                <Form.Group as={Col} controlId="companyName">
                  <Form.Label>의뢰 회사명</Form.Label>
                  <Form.Control
                    type="text"
                    plaintext
                    readOnly
                    defaultValue={requestId ? request!.companyName : state.work.companyName}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="teamName">
                  <Form.Label>요청자 팀명*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="팀명을 입력하세요. (최대 200자)"
                    maxLength={200}
                    defaultValue={requestId && request ? request.requesterTeam : ''}
                    onChange={(event) => {
                      setTeam(event.target.value.trimLeft());
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="name">
                  <Form.Label>요청자명*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="이름을 입력하세요. (최대 200자)"
                    maxLength={200}
                    defaultValue={requestId && request ? request.requesterName : ''}
                    onChange={(event) => {
                      setName(event.target.value.trimLeft());
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="title">
                  <Form.Label>요청명*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="제목을 입력하세요. (최대 200자)"
                    maxLength={200}
                    defaultValue={requestId && request ? request.requestTitle : ''}
                    onChange={(event) => {
                      setTitle(event.target.value.trimLeft());
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="content">
                  <Form.Label>업무 상세 내용*</Form.Label>
                  <TextareaAutosize
                    id={'content'}
                    placeholder={'내용을 입력하세요 (최대 2000자)'}
                    minRows={10}
                    maxLength={2000}
                    style={{ width: '100%', resize: 'none' }}
                    defaultValue={requestId && request ? request.requestContent : ''}
                    onChange={(event) => {
                      setContent(event.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Row>
          <Row>
            <Button
              variant="secondary"
              onClick={() => {
                requestId ? history.push('/request') : history.push('/work');
              }}
            >
              취소
            </Button>
            <Button variant="success" onClick={handleSaveBtnOnclick} disabled={!team || !name || !title || !content}>
              저장
            </Button>
          </Row>
        </div>
      )}
    </div>
  );
};

export default RequestContainer;
