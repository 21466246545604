import './LoadingOverlay.scss';

import React, { ReactElement } from 'react';
import LoadingOverlay from 'react-loading-overlay';

import CommonSpinner from './Spinner';

export interface CommonLoadingOverlay {
  active: boolean;
  children: () => JSX.Element;
}

const CommonLoadingOverlay: React.FC<CommonLoadingOverlay> = ({
  active,
  children,
}: CommonLoadingOverlay): ReactElement => {
  return (
    <LoadingOverlay active={active} spinner={<CommonSpinner />}>
      {children()}
    </LoadingOverlay>
  );
};

export default CommonLoadingOverlay;
