import './WorkPage.scss';

import React, { useContext } from 'react';
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { Loader } from 'rsuite';

import { AuthContext } from '../../App';
import CommonSpinner from '../../layouts/Spinner';
import CompanyListContainer from './component/CompanyListContainer';
import RequestContainer from './component/RequestContainer';
import RequestDetailContainer from './component/RequestDetailContainer';
import RequestListContainer from './component/RequestListContainer';
import WorkSideNavSection from './WorkSideNavSection';

const WorkPage = () => {
  const { state } = useContext(AuthContext);
  return (
    <div id="WorkPage">
      {state.common.isLoading && <Loader backdrop center content={<CommonSpinner />} />}
      <Navbar bg="dark" variant="dark">
        인력소싱지원시스템
      </Navbar>
      <Container id="page">
        <Row>
          <Col lg="2">
            <WorkSideNavSection />
          </Col>
          <Col lg="10">
            <Switch>
              <Route exact path="/work" component={CompanyListContainer} />
              <Route exact path="/request/enroll" component={RequestContainer} />
              <Route exact path="/request/enroll/:id" component={RequestContainer} />
              <Route exact path="/request" component={RequestListContainer} />
              <Route exact path="/request/detail/:id" component={RequestDetailContainer} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WorkPage;
