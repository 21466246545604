import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import uuidv4 from 'uuid';

import { CommonActionType } from '../reducer/actions';
import { Status } from './common/model/enum/Status.enum';
import { Error } from './common/model/Error';
import { Service, ServicePort } from './common/model/Service';
import store from './common/utils/store';
import SessionService from './SessionService';

const setLoading = (isLoading: boolean) => {
  store.dispatch({ type: CommonActionType.LOADING, isLoading: isLoading });
};

export default class BaseService {
  private environment: string;
  private apiLocation: string;
  private protocolSSL = 'https://';
  private protocol = 'http://';
  private sessionService = new SessionService();
  private languageCode = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'KO';
  protected defaultPagingSize = 10;

  constructor() {
    this.apiLocation = process.env.REACT_APP_API_URL || '';
    this.environment = process.env.REACT_APP_NODE_ENV || '';
  }

  public getAPILocation = (): string => {
    return this.apiLocation;
  };

  public setEnvironment = (env: string): void => {
    this.environment = env;
  };

  public getEnvironment = (): string => {
    return this.environment;
  };

  private getCorrelationId = (): string => {
    return window.location.pathname === '/'
      ? 'root'.concat('_').concat(uuidv4())
      : window.location.pathname.concat('_').concat(uuidv4());
  };

  // Serverless실습용 header
  // protected headers = {
  //   Accept: 'application/json',
  //   Authorization: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia', // TO-DO : 임의값 적용, 인증관련 로직적용시 변경예정
  //   'Content-Type': 'application/json',
  //   'X-Session-Id': this.sessionService.getSessionId(),
  //   'x-correlation-id': this.getCorrelationId(),
  //   'x-language-code': this.languageCode,
  // };

  // Spring boot실습용 header
  // Authorization의 JWT토큰은 33년 1월까지 유효함, 이후 갱신 필요 - v1/jwt메소드 사용
  protected headers = {
    Accept: 'application/json',
    Authorization:
      'eyJ0eXBlIjoiand0IiwiYWxnIjoiSFMyNTYifQ.eyJhdWQiOiJidWlsZGNlbnRlcl9hbWNhbXBfY2xpZW50IiwiZW1haWxBZGRyZXNzIjoidGVzdDAxQGxnY25zLmNvbSIsImlzcyI6ImJ1aWxkY2VudGVyX2FtY2FtcCIsImV4cCI6MTk4OTQ3MzE0OCwiaWF0IjoxNjc0MTEzMTQ4LCJtZW1iZXJJZCI6MX0.1WeqkZ1rTZFItjmFbbLy4IysW1q1yiXwjwzSD4Qnu0Y',
    'Content-Type': 'application/json',
    'x-correlation-id': this.getCorrelationId(),
    'x-api-key': '12345',
  };

  protected config: AxiosRequestConfig = {
    method: 'GET',
    url: '',
    headers: this.headers,
    data: {},
    timeout: 30000,
  };

  /* eslint-disable */
  public async fnRest(method: string, url: string, params?: any, requestBody?: any, service?: string, showLoadingBar: boolean = true): Promise<any> {
    this.config.method = this.fnCheckHTTPMethod(method);
    this.config.url = this.fnCheckServiceUrl(url, service ? service : 'etc');
    this.config.params = params ? params : {};
    this.config.data = requestBody ? requestBody : {};

    try {
      if (showLoadingBar) setLoading(true);
      const response: AxiosResponse<any> = await axios.request(this.config);

      if (response && response.data) {
        if (response.data.successOrNot === "Y") {
          if (response.data.sessionId) {
            this.sessionService.setSessionId(response.data.sessionId);
          }
          return response.data;

        } else if (response.status === Status.RESPONSE_STATUS_200) {
          return response.data;
        } else {
          const noProperError: Error = {
            successOrNot: 'N',
            statusCode: 'NO_PROPER',
            data: {},
          };
          throw noProperError;
        }
      } else {
        const noResponseError: Error = {
          successOrNot: 'N',
          statusCode: 'NO_RESPONSE',
          data: {},
        };
        throw noResponseError;
      }
    } catch (error) {
      if (error.response && error.response.status === Status.RESPONSE_STATUS_401) {
        this.sessionService.deleteSessionInfo();
        window.location.assign('/unauthorized');
        return false;
      } else if (error.response && error.response.status === Status.RESPONSE_STATUS_500) {
        const internalServerError: Error = {
          successOrNot: 'N',
          statusCode: 'INTERNAL_SERVER',
          data: {},
        }
        throw internalServerError;
      } else if (error && (error as Error).successOrNot) {
        throw error;
      } else {
        const unknownError: Error = {
          successOrNot: 'N',
          statusCode: 'UNKNOWN_SERVER',
          data: {},
        };
        throw unknownError;
      }
    } finally {
      if (showLoadingBar) setLoading(false);
    }
  }

  public fnCheckServiceUrl = (url: string, service: string) => {
    let serviceUrl = '';

    // newbie pod 에서는 BE 세팅 없이 클라우드 직접 호출로 진행
    switch (service) {
      case Service.BOARD:
        // case1: develop 브랜치에 직접 merge하는 경우
        if (this.environment === 'local') {
          serviceUrl = this.protocol + this.apiLocation + url;
        } else {
          serviceUrl = this.protocolSSL + this.apiLocation + url;
        }
        break;
      case Service.MEMBER:
        serviceUrl = this.protocolSSL + Service.MEMBER + '.' + this.apiLocation + url;
        break;
      default:
        serviceUrl = url;
    }

    /*
       if (this.environment === 'local') {
         // serviceUrl = this.apiLocation;
         switch (service) {
           case Service.BOARD:
             serviceUrl += this.apiLocation + url;
         serviceUrl = this.apiLocation;
         switch (service) {
           case Service.BOARD:
             serviceUrl += ':' + ServicePort.BOARD + url;
             break;
           case Service.MEMBER:
             serviceUrl += ':' + ServicePort.MEMBER + url;
             break;
           default:
             serviceUrl = url;
         }
       } else {
         switch (service) {
           case Service.BOARD:
             // case1: develop 브랜치에 직접 merge하는 경우
             serviceUrl = this.protocolSSL + Service.BOARD + '.' + this.apiLocation + url;
             serviceUrl = this.protocol + Service.BOARD + '.' + this.apiLocation + url;
             // case2: pod브랜치 또는 group별 브랜치에 merge하는 경우
             // serviceUrl = this.protocol + '(BE브랜치명)-' + Service.BOARD + '.' + this.apiLocation + url;
             break;
           case Service.MEMBER:
             serviceUrl = this.protocolSSL + Service.MEMBER + '.' + this.apiLocation + url;
             serviceUrl = this.protocol + Service.MEMBER + '.' + this.apiLocation + url;
             break;
           default:
             serviceUrl = url;
         }
       }
    */
    return serviceUrl;
  };
  /* eslint-enable */

  public fnCheckHTTPMethod = (method: string) => {
    let httpMethod: Method;
    switch (method) {
      case 'GET':
      case 'get':
        httpMethod = 'GET';
        break;
      case 'POST':
      case 'post':
        httpMethod = 'POST';
        break;
      case 'PUT':
      case 'put':
        httpMethod = 'PUT';
        break;
      case 'PATCH':
      case 'patch':
        httpMethod = 'PATCH';
        break;
      case 'DELETE':
      case 'delete':
        httpMethod = 'DELETE';
        break;
      default:
        httpMethod = 'GET';
    }
    return httpMethod;
  };

  public getQueryStringFormat = (queryParam: any) => {
    const keys = Object.keys(queryParam);
    let queryString = '?';
    for (const key of keys) {
      if (queryString.length > 1) {
        queryString = queryString.concat('&');
      }
      queryString = queryString.concat(key).concat('=').concat(encodeURIComponent(queryParam[key]));
    }
    return queryString;
  };
}
