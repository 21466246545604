import './BoardRegistForm.scss';

import { MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import BoardService from '../../../service/board15/BoardService';
import { RegistBoardRequest } from '../../../service/board15/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode } from '../../../service/common/model/CommonCode';

const BoardRegistForm: React.FC = (): ReactElement => {
  const history = useHistory();

  const [title, setTitle] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [categoryList, setCategoryList] = useState([] as CommonCode[]);

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(e.target.value);
  };

  const onChangeCategory = (e) => {
    setSelectedCategory(e.target.value as string);
  };

  const onChangeContent = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setContent(e.target.value);
  };

  const handleOnCancel = useCallback(() => {
    if (window.confirm('정말로 게시글 등록을 취소하시겠습니까?')) {
      history.push('/board-15');
    }
  }, [history]);

  const handleOnSave = useCallback(() => {
    const boardService = new BoardService();

    const board: RegistBoardRequest = {
      boardTitle: title,
      category: selectedCategory,
      boardContents: content,
    };

    void boardService.createBoardData(board).then((res) => {
      if (res.successOrNot === 'Y') {
        history.push('/board-15');
      }
    });
  }, [title, selectedCategory, content, history]);

  useEffect(() => {
    const commonService = new CommonCodeService();

    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    void getCategoryList().then((res) => {
      if (res && res.length > 0) {
        setSelectedCategory(res[0].codeId);
        setCategoryList(res);
      }
    });
  }, []);

  return (
    <div id="BoardRegistForm">
      <div className="title">
        <span id="title" data-testid="title">
          나도한마디
        </span>
        <span className="subTitle">등록하기</span>
        <Form className="inputForm">
          <Form.Row className="category-row">
            <Row>
              <Col className="title-col">
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="제목을 입력하세요."
                  id="inputTitle"
                  data-testid="inputTitle"
                  className="inputTitle"
                  maxLength={40}
                  onChange={onChangeTitle}
                />
              </Col>
              <Col className="categorySelect">
                <Select
                  className="category"
                  defaultValue={''}
                  value={categoryList.length ? selectedCategory : ''}
                  onChange={onChangeCategory}
                >
                  {categoryList.map((category) => {
                    return (
                      <MenuItem key={category.codeId} value={category.codeId}>
                        {category.codeName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </Form.Row>
          <Form.Row id="contents">
            <Form.Control as="textarea" maxLength={200} rows={20} onChange={onChangeContent} />
          </Form.Row>
          <div className="btnArea">
            <Button
              id="registBtn"
              type="button"
              onClick={handleOnSave}
              disabled={!title || !content || !selectedCategory}
            >
              <p>등록</p>
            </Button>
            <Button id="cancelBtn" type="button" onClick={handleOnCancel}>
              <p>취소</p>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BoardRegistForm;
