import './MemberRegistrationForm.scss';

import React, { useContext, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../../../App';
import { CommonActionType } from '../../../../reducer/actions';
import CommonResponse from '../../../../service/common/model/CommonResponse';
import { memberRegistFormFieldCode } from '../../../../service/member/login/model/CommonConstants';
import MemberRegistrationService from '../../../../service/member/memberRegistration/MemberRegistrationService';
import Member from '../../../../service/member/memberRegistration/model/Member';
import StepInformation from '../../../../service/member/memberRegistration/model/StepInformation';

export interface MemberRegistrationFormProps {
  stepParams: StepInformation;
  setStepParams: (stepParams: StepInformation) => void;
  memberRegistrationService: MemberRegistrationService;
  setMemberInfo: Function;
}
export const MemberRegistrationForm: React.FC<MemberRegistrationFormProps> = (props: MemberRegistrationFormProps) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const [emailCheckVisible, setEmailCheckVisible] = useState(false);
  const history = useHistory();

  let stepParams: StepInformation;

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: CommonActionType.LOADING, loading: { isLoading: _isLoading } });
  };

  const [formState, setFormState] = useState({
    formFieldsValue: {
      emailAddress: '',
      password: '',
      passwordConfirm: '',
      memberName: '',
    },
    formFieldsErrorMessage: {
      emailAddress: '',
      password: '',
      passwordConfirm: '',
      memberName: '',
    },
    formFieldsValidity: {
      emailAddress: false,
      password: false,
      passwordConfirm: false,
      memberName: false,
    },
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const fieldsValue = formState.formFieldsValue;
    fieldsValue[name] = value;

    setFormState({
      ...formState,
      formFieldsValue: fieldsValue,
    });

    handleValidation(name, value);
  };

  const handleBlur = async ({ target }) => {
    const { name, value } = target;
    await handleEmailValidation(name, value);
  };

  const handleEmailValidation = async (name, value) => {
    const fieldsErrorMessage = formState.formFieldsErrorMessage;
    const fieldsValidity = formState.formFieldsValidity;

    if (fieldsValidity[name]) {
      let response: CommonResponse = {
        successOrNot: 'N',
        statusCode: '',
      };
      response = await props.memberRegistrationService.checkExistFlagEmailAddress(value);

      if (response.successOrNot === 'Y') {
        fieldsErrorMessage[name] = '';
        setEmailCheckVisible(true);
      } else if (response.statusCode === 'DUPLICATION.EMAIL') {
        setEmailCheckVisible(false);
        fieldsValidity[name] = false;
        fieldsErrorMessage[name] = t('admin.message.backEnd.DUPLICATION.EMAIL');
      } else {
        setEmailCheckVisible(false);
        fieldsValidity[name] = false;
        alert(t('admin.message.backEnd.UE'));
      }
    }

    setFormState({
      ...formState,
      formFieldsErrorMessage: fieldsErrorMessage,
      formFieldsValidity: fieldsValidity,
    });
  };

  const handleValidation = (name, value) => {
    const fieldsValue = formState.formFieldsValue;
    const fieldsErrorMessage = formState.formFieldsErrorMessage;
    const fieldsValidity = formState.formFieldsValidity;
    const isEmail = name === memberRegistFormFieldCode.EMAIL;
    const isPassword = name === memberRegistFormFieldCode.PASSWORD;
    const isPasswordConfirm = name === memberRegistFormFieldCode.PASSWORD_CONFIRM;
    const isMemberName = name === memberRegistFormFieldCode.MEMBER_NAME;
    const emailTest = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    const passwordTest = /^(?=.*[A-Za-zㄱ-ㅎ|ㅏ-ㅣ|가-힣])(?=.*\d)(?=.*[!"#$%&'()*+,./:;<=>?@\^_`{|}~-])[^\s]{8,}$/;

    if (isEmail) {
      fieldsValidity[name] = value.length > 0;
      if (fieldsValidity[name]) {
        fieldsValidity[name] = emailTest.test(value);
        if (fieldsValidity[name]) {
          fieldsErrorMessage[name] = '';
        } else {
          setEmailCheckVisible(false);
          fieldsErrorMessage[name] = t('admin.message.MemberRegistrationForm.emailAddress.common');
        }
      } else {
        setEmailCheckVisible(false);
        fieldsErrorMessage[name] = t('admin.message.MemberRegistrationForm.emailAddress.required');
      }
    } else if (isPassword) {
      fieldsValue[memberRegistFormFieldCode.PASSWORD_CONFIRM] = '';
      fieldsValidity[name] = value.length > 0;
      fieldsErrorMessage[name] = fieldsValidity[name]
        ? ''
        : t('admin.message.MemberRegistrationForm.password.required');
      if (fieldsValidity[name]) {
        fieldsValidity[name] = passwordTest.test(value);
        if (!fieldsValidity[name]) {
          fieldsErrorMessage[name] = t('admin.message.MemberRegistrationForm.password.common');
        }
      }
    } else if (isPasswordConfirm) {
      fieldsValidity[name] = value.length > 0;
      fieldsErrorMessage[name] = fieldsValidity[name]
        ? ''
        : t('admin.message.MemberRegistrationForm.password.required');
      if (fieldsValidity[name]) {
        if (fieldsValue[memberRegistFormFieldCode.PASSWORD] !== value) {
          fieldsValidity[name] = false;
          fieldsErrorMessage[name] = t('admin.message.MemberRegistrationForm.password.common');
        }
      }
    } else if (isMemberName) {
      fieldsValidity[name] = value.length > 0;
      fieldsErrorMessage[name] = fieldsValidity[name]
        ? ''
        : t('admin.message.MemberRegistrationForm.memberName.required');
      if (fieldsValidity[name]) {
        if (value.length > 30) {
          fieldsValidity[name] = false;
          fieldsErrorMessage[name] = t('admin.message.MemberRegistrationForm.memberName.maxLength');
        }
      }
    }

    setFormState({
      ...formState,
      formFieldsErrorMessage: fieldsErrorMessage,
      formFieldsValidity: fieldsValidity,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { formFieldsValue, formFieldsValidity } = formState;
    if (Object.values(formFieldsValidity).every(Boolean)) {
      const member: Member = { ...formState.formFieldsValue, termsAgree: props.stepParams.termAgreeInfo?.termsAgree };

      setLoading(true);
      const response: CommonResponse = await props.memberRegistrationService.registMember(member);
      setLoading(false);
      if (response.successOrNot === 'Y') {
        stepParams = {
          currentStep: 3,
        };
        props.setStepParams(stepParams);
        props.setMemberInfo({
          emailAddress: member.emailAddress,
          memberName: member.memberName,
        });
        history.push(history.location.pathname + history.location.search + '#step3');
      } else {
        alert(t('admin.message.backEnd.UE'));
      }
    } else {
      handleValidation(memberRegistFormFieldCode.EMAIL, formFieldsValue.emailAddress);
      void handleEmailValidation(memberRegistFormFieldCode.EMAIL, formFieldsValue.emailAddress);
      handleValidation(memberRegistFormFieldCode.PASSWORD, formFieldsValue.password);
      handleValidation(memberRegistFormFieldCode.PASSWORD_CONFIRM, formFieldsValue.passwordConfirm);
      handleValidation(memberRegistFormFieldCode.MEMBER_NAME, formFieldsValue.memberName);
    }
  };

  return (
    <div id="MemberRegistrationForm">
      <Form.Group id="MainInformationForm">
        <Form.Row>
          <Form.Group id="formEmail">
            <Form.Label>{t('admin.label.memberRegistrationForm.emailAddress')}</Form.Label>
            <br />
            <Form.Control
              id="emailAddress"
              name="emailAddress"
              data-testid="emailAddress"
              className="formik-field"
              required
              type="text"
              placeholder={t('admin.label.memberRegistrationForm.emailAddressPlaceholder')}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                void handleBlur(e);
              }}
              value={formState.formFieldsValue.emailAddress}
              isInvalid={!formState.formFieldsValidity.emailAddress}
              autoFocus
            />
            {emailCheckVisible && (
              <div className="checked-area">
                <i id="email-checked" data-testid="email-checked" className="material-icons">
                  check
                </i>
              </div>
            )}
            <Form.Row id="emailAddressFeedback" data-testid="emailAddressFeedback" className="invalid-feedback">
              {formState.formFieldsErrorMessage.emailAddress}
            </Form.Row>
          </Form.Group>
        </Form.Row>
        <Form.Row className="top-empty-space">
          <Form.Group id="formPassword">
            <Form.Label>{t('admin.label.memberRegistrationForm.password')}</Form.Label>
            <Form.Control
              id="password"
              name="password"
              data-testid="password"
              required
              type="password"
              placeholder={t('admin.label.memberRegistrationForm.passwordPlaceholder')}
              onChange={(e) => {
                handleChange(e);
              }}
              value={formState.formFieldsValue.password}
              isInvalid={!formState.formFieldsValidity.password}
            />
            <Form.Control
              id="passwordConfirm"
              name="passwordConfirm"
              data-testid="passwordConfirm"
              required
              type="password"
              placeholder={t('admin.label.memberRegistrationForm.passwordConfirmPlaceholder')}
              onChange={(e) => {
                handleChange(e);
              }}
              value={formState.formFieldsValue.passwordConfirm}
              isInvalid={!formState.formFieldsValidity.passwordConfirm}
            />
            <Form.Row id="passwordFeedback" data-testid="passwordFeedback" className="invalid-feedback">
              {formState.formFieldsErrorMessage.password || formState.formFieldsErrorMessage.passwordConfirm}
            </Form.Row>
          </Form.Group>
        </Form.Row>
        <Form.Row className="top-empty-space">
          <Form.Group>
            <Form.Label>{t('admin.label.memberRegistrationForm.memberName')}</Form.Label>
            <Form.Control
              id="memberName"
              name="memberName"
              data-testid="memberName"
              required
              type="text"
              placeholder={t('admin.label.memberRegistrationForm.memberNamePlaceholder')}
              onChange={(e) => {
                handleChange(e);
              }}
              value={formState.formFieldsValue.memberName}
              isInvalid={!formState.formFieldsValidity.memberName}
            />
            <Form.Row id="memberNameFeedback" data-testid="memberNameFeedback" className="invalid-feedback">
              {formState.formFieldsErrorMessage.memberName}
            </Form.Row>
          </Form.Group>
        </Form.Row>
        <Button
          id="registrationButton"
          name="registrationButton"
          data-testid="registrationButton"
          type="submit"
          onClick={(e) => {
            void handleSubmit(e);
          }}
          className="submit-button top-empty-space"
          disabled={
            !formState.formFieldsValidity.emailAddress ||
            !formState.formFieldsValidity.password ||
            !formState.formFieldsValidity.passwordConfirm ||
            !formState.formFieldsValidity.memberName
          }
        >
          {t('admin.label.memberRegistrationForm.button.registration')}
        </Button>
      </Form.Group>
    </div>
  );
};
export default MemberRegistrationForm;
