import './BoardRegistForm.scss';

import { MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import BoardService from '../../../service/board3/BoardService';
import { RegistBoardRequest } from '../../../service/board3/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode } from '../../../service/common/model/CommonCode';

const BoardRegistForm: React.FC = (): ReactElement => {
  // state
  const [title, setTitle] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [content, setContent] = useState<string>('');

  const history = useHistory();

  // service, model
  const commonService = new CommonCodeService();
  const boardService = new BoardService();
  const [categoryList, setCategoryList] = useState<CommonCode[]>([]);

  // function
  const onCancelRegister = useCallback(() => {
    if (window.confirm('정말로 게시글 등록을 취소하시겠습니까?')) {
      history.push('/board-3');
    }
  }, [history]);

  const handleSaveBtnOnClick = () => {
    if (window.confirm('게시글을 등록하시겠습니까?')) {
      const requestData: RegistBoardRequest = {
        boardTitle: title,
        category: category,
        boardContents: content,
      };
      void boardService.createBoardData(requestData).then((response) => {
        if (response.successOrNot === 'Y') {
          console.log('title : ' + title);
          console.log('category : ' + category);
          console.log('content : ' + content);
          history.push('/board-3');
        } else {
          alert('등록에 실패하였습니다.');
        }
      });
    }
  };

  // useEffect
  useEffect(() => {
    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    void getCategoryList().then((res) => {
      if (res && res.length > 0) {
        setCategory(res[0].codeId);
        setCategoryList(res);
      }
    });
  }, []);

  return (
    <div id="BoardRegistForm">
      <div className="title">
        <span id="title" data-testid="title">
          Mily의 나도 한 마디
        </span>
        <span className="subTitle" style={{ marginLeft: '10px' }}>
          등록하기
        </span>
        <Form className="inputForm">
          <Form.Row className="category-row">
            <Row>
              <Col className="title-col" style={{ marginTop: '10px' }}>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="제목을 입력하세요."
                  id="inputTitle"
                  data-testid="inputTitle"
                  className="inputTitle"
                  maxLength={40}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Col>
              <Col className="categorySelect">
                <Select
                  className="category"
                  defaultValue=""
                  value={categoryList.length ? category : ''}
                  onChange={(e) => setCategory(e.target.value as string)}
                  style={{ paddingLeft: '10px' }}
                >
                  {categoryList.map((category) => {
                    return (
                      <MenuItem key={category.codeId} value={category.codeId}>
                        {category.codeName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </Form.Row>
          <Form.Row id="contents">
            <Form.Control as="textarea" maxLength={200} rows={20} onChange={(e) => setContent(e.target.value)} />
          </Form.Row>
          <div className="btnArea" style={{ marginTop: '15px' }}>
            <Button
              id="registBtn"
              type="button"
              onClick={handleSaveBtnOnClick}
              disabled={!title || !content || !category}
            >
              <p>등록</p>
            </Button>
            <Button id="cancelBtn" type="button" onClick={onCancelRegister}>
              <p>취소</p>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BoardRegistForm;
