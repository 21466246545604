import './LoginFooter.scss';

import React, { ReactElement } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const LoginFooter: React.FC = (): ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div id="LoginFooter">
      <div className="signUp">
        {t('admin.label.LoginFooter.noId')}
        <Button id="memberRegButton" onClick={() => history.push('/memberRegistration')}>
          {t('admin.label.LoginFooter.signUp')}
        </Button>
      </div>
      <div className="changePassword">
        <Button>{t('admin.label.LoginFooter.findPassword')}</Button>
      </div>
    </div>
  );
};

export default LoginFooter;
