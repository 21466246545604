import './BoardRegistForm.scss';

import { MenuItem, Select } from '@material-ui/core';
import { title } from 'process';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import BoardService from '../../../service/board10/BoardService';
import { RegistBoardRequest } from '../../../service/board10/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode } from '../../../service/common/model/CommonCode';

const BoardRegistForm: React.FC = (): ReactElement => {
  const [boardTitle, setBoardTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [contents, setContents] = useState('');
  const [categoryList, setCategoryList] = useState<CommonCode[]>([]);
  const history = useHistory();

  const handleTitleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    console.log(event.target.value);
    setBoardTitle(event.target.value);
  };
  const handleCategoryOnChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleContentsOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setContents(event.target.value);
  };

  const handleOnSave = useCallback(() => {
    const boardService = new BoardService();

    const board: RegistBoardRequest = {
      boardTitle: title,
      category: selectedCategory,
      boardContents: contents,
    };

    void boardService.createBoardData(board).then((res) => {
      if (res.successOrNot === 'Y') {
        history.push('/board-10');
      }
    });
  }, [title, selectedCategory, contents, history]);

  const handleCancelBtnOnClick = () => {
    history.push('/board-10');
  };

  const commonService = new CommonCodeService();

  useEffect(() => {
    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    void getCategoryList().then((res) => {
      if (res && res.length > 0) {
        setSelectedCategory(res[0].codeId);
        setCategoryList(res);
      }
    });
  }, []); // eslint-disable-line
  return (
    <div id="BoardRegistForm">
      <div className="title">
        <span id="title" data-testid="title">
          나도한마디
        </span>
        <span className="subTitle">등록하기</span>
      </div>
      <Form className="inputForm">
        <Form.Row className="category-row">
          <Row>
            <Col className="title-col">
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="제목을 입력하세요."
                id="inputTitle"
                name="inputTitle"
                data-testid="inputTitle"
                className="inputTitle"
                onChange={handleTitleOnChange}
                value={boardTitle}
                maxLength={40}
              />
            </Col>
            <Col className="categorySelect">
              <Select className="category" defaultValue="" value={selectedCategory} onChange={handleCategoryOnChange}>
                {categoryList.map((category, idx) => {
                  return (
                    <MenuItem key={idx} value={category.codeId}>
                      {category.codeName}
                    </MenuItem>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Form.Row>
        <Form.Row id="contents">
          <Form.Control as="textarea" onChange={handleContentsOnChange} maxLength={200} value={contents} rows={20} />
        </Form.Row>
        <div className="btnArea">
          <Button
            id="registBtn"
            type="button"
            disabled={!boardTitle || !contents || !selectedCategory}
            onClick={handleOnSave}
          >
            <p>등록</p>
          </Button>
          <Button id="cancelBtn" type="button" onClick={handleCancelBtnOnClick}>
            <p>취소</p>
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default BoardRegistForm;
