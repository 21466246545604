import BaseService from '../../BaseService';
import CommonResponse from '../../common/model/CommonResponse';
import { Service } from '../../common/model/Service';
import Member from './model/Member';

export default class MemberRegistrationService {
  public baseService: BaseService = new BaseService();

  async checkExistFlagEmailAddress(emailAddress: string): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/v1/member/email';
    const serviceName = Service.MEMBER;
    const body = { emailAddress: emailAddress };

    try {
      response = await this.baseService.fnRest(method, url, null, body, serviceName, true);
      // response = {
      //   successOrNot: 'Y',
      //   statusCode: 'SUCCESS'
      // }
    } catch (error) {
      response = error;
    }

    return response;
  }

  async registMember(member: Member): Promise<CommonResponse> {
    let response: CommonResponse;

    const method = 'POST';
    const url = '/v1/member';
    const serviceName = Service.MEMBER;
    const body = JSON.stringify(member);

    try {
      response = await this.baseService.fnRest(method, url, null, body, serviceName, true);
      // response = {
      //   successOrNot: 'Y',
      //   statusCode: 'SUCCESS',
      // }
    } catch (error) {
      response = error;
    }

    return response;
  }
}
