import React, { ReactElement } from 'react';
import { Route } from 'react-router-dom';

import BoardDetailContainer from './BoardDetailContainer';
import BoardEditContainer from './BoardEditContainer';
import BoardListContainer from './BoardListContainer';
import BoardRegistContainer from './BoardRegistContainer';

const BoardPage15 = (): ReactElement => {
  return (
    <>
      <Route exact path="/board-15" component={BoardListContainer}></Route>
      <Route exact path="/board-15/detail/:id" component={BoardDetailContainer}></Route>
      <Route exact path="/board-15/regist" component={BoardRegistContainer}></Route>
      <Route exact path="/board-15/edit/:id" component={BoardEditContainer}></Route>
    </>
  );
};

export default BoardPage15;
