import './BoardDetailInfo.scss';

import moment from 'moment';
import React, { ReactElement } from 'react';
import { Row } from 'react-bootstrap';

import { BoardData } from '../../../service/board4/model/Board';
import { CategoryName } from '../../../service/common/model/CommonCode';
import { CommonDateFormat } from '../../../service/common/model/enum/CommonDateFormat';

interface Props {
  detail: BoardData;
}

const BoardDetailInfo: React.FC<Props> = ({ detail }: Props): ReactElement => {
  return (
    <div id="BoardDetailInfo">
      <div>
        <Row id="category">
          <span>{CategoryName[detail.category]}</span>
        </Row>
        <Row id="title">{detail.boardTitle}</Row>
        <Row>
          <span className="info">{detail.createMemberName}</span>
          <span className="info">
            최종수정일시 {moment(detail.updatedDatetime).format(CommonDateFormat.DATETIME_HM)}
          </span>
          <span className="info">등록일시 {moment(detail.createdDatetime).format(CommonDateFormat.DATETIME_HM)}</span>
        </Row>
        <hr />
        <Row id="contents">{detail.boardContents}</Row>
        <hr />
      </div>
    </div>
  );
};

export default BoardDetailInfo;
