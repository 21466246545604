import React, { ReactElement } from 'react';

import BoardEditForm from './component/BoardEditForm';

const BoardEditContainer: React.FC = (): ReactElement => {
  return (
    <div id="BoardEditContainer" data-testid="BoardEditContainer">
      <BoardEditForm />
    </div>
  );
};

export default BoardEditContainer;
