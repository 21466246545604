import 'bootstrap/dist/css/bootstrap.min.css';
import 'moment/locale/ko';
import './App.scss';

import React, { createContext, useReducer } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { ErrorPage, LoginPage, MainPage, MemberRegistrationPage, UnauthorizedErrorPage } from './pages';
import WorkPage from './pages/work/WorkPage';
import { initialState, reducer } from './reducer';
import { State } from './reducer/states';
import store from './service/common/utils/store';
import SessionService from './service/SessionService';

interface AuthContextProps {
  state: State;
  dispatch: React.Dispatch<any>;
}

export const AuthContext = createContext({} as AuthContextProps);

const App: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const sessionService = new SessionService();

  if (!store.isReady) {
    store.isReady = true;
    store.dispatch = (params) => dispatch(params);
    Object.freeze(store);
  }

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <Router>
        <Switch>
          <Route exact path="/">
            {sessionService.getSessionId() ? <Redirect to="/board" /> : <Redirect to="/login" />}
          </Route>
          <Route path="/board">{sessionService.getSessionId() ? <MainPage /> : <Redirect to="/login" />}</Route>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((value) => {
            return (
              <Route key={value} path={'/board-' + value}>
                {sessionService.getSessionId() ? <MainPage /> : <Redirect to="/login" />}
              </Route>
            );
          })}
          <Route exact path="/login" render={LoginPage} />
          <Route exact path="/memberRegistration" render={MemberRegistrationPage} />
          <Route exact path="/unauthorized" render={UnauthorizedErrorPage} />
          <Route path="/event" component={MainPage} />
          <Route path="/work" component={WorkPage} />
          <Route path="/request" component={WorkPage} />
          <Route component={ErrorPage} />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
};
export default App;
