import './ConfirmModal.scss';

import React, { ReactElement } from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface Props {
  show: boolean;
  onHide: Function;
  title: string;
  message?: string;
  confirmLabel: string;
  cancelLabel?: string;
  onConfirm: Function;
}

export const ConfirmModal: React.FC<Props> = (props: Props): ReactElement => {
  return (
    <Modal show={props.show} onHide={props.onHide} centered animation={false} id="confirmModal">
      <Modal.Header closeButton className="header">
        <Modal.Title className="title">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        <p>{props.message}</p>
      </Modal.Body>
      <Modal.Footer className="footer">
        {props.cancelLabel && <Button onClick={() => props.onHide()}>{props.cancelLabel}</Button>}
        <Button onClick={() => props.onConfirm()}>{props.confirmLabel}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
