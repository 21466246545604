import React, { ReactElement } from 'react';
import { Route } from 'react-router-dom';

import BoardDetailContainer from './BoardDetailContainer';
import BoardEditContainer from './BoardEditContainer';
import BoardListContainer from './BoardListContainer';
import BoardRegistContainer from './BoardRegistContainer';

const BoardPage13 = (): ReactElement => {
  return (
    <>
      <Route exact path="/board-13" component={BoardListContainer}></Route>
      <Route exact path="/board-13/detail/:id" component={BoardDetailContainer}></Route>
      <Route exact path="/board-13/regist" component={BoardRegistContainer} />
      <Route exact path="/board-13/edit/:id" component={BoardEditContainer}></Route>
    </>
  );
};

export default BoardPage13;
