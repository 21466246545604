import BaseService from '../../BaseService';
import CommonResponse from '../../common/model/CommonResponse';
import { Service } from '../../common/model/Service';

export default class TermService {
  public baseService: BaseService = new BaseService();

  requestTermInfo() {
    let response: CommonResponse;

    const method = 'GET';
    const url = '/v1/terms';
    const serviceName = Service.MEMBER;

    try {
      //response = await this.baseService.fnRest(method, url, null, null, serviceName, true);
      response = {
        successOrNot: 'Y',
        statusCode: 'SUCCESS',
        data: [
          {
            termsId: 1,
            mandatoryTermsYn: 'Y',
            termsTitle: '만 14세 이상입니다.',
            termsContents: '만 14세 미만은 회원가입을 할 수 없습니다.',
            termsDetailContents:
              '<p className="terms__content">↵                AIC사내게시판은 이용자의 개인정보를 중요시하며, 개인정보의 보호와 관련하여 『정보통신망 이용촉진 및↵                정보보호 등에 관한 법률』 및 『개인정보보호법』 등 관련법 상의 개인정보보호 규정을 준수합니다.↵              </p>',
          },
          {
            termsId: 2,
            mandatoryTermsYn: 'N',
            termsTitle: '개인정보 제3자 제공 동의',
            termsContents: '개인정보 제3자 제공에 동의합니다.',
          },
        ],
      };
    } catch (error) {
      response = error;
    }

    return response;
  }
}
