import './BoardRegistForm.scss';

import { MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import BoardService from '../../../service/board6/BoardService';
import { RegistBoardRequest } from '../../../service/board6/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode } from '../../../service/common/model/CommonCode';

const BoardRegistForm: React.FC = (): ReactElement => {
  const [boardTitle, setBoardTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [contents, setContents] = useState('');
  const [categoryList, setCategoryList] = useState<CommonCode[]>([]);

  const history = useHistory();
  const commonService = new CommonCodeService();
  const boardService = new BoardService();

  const handleTitleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setBoardTitle(e.target.value);
  };

  const handleCategoryOnChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleContentsOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setContents(e.target.value);
  };

  // 등록
  const handleSave = () => {
    const requestData: RegistBoardRequest = {
      boardTitle: boardTitle,
      category: selectedCategory,
      boardContents: contents,
    };

    void boardService.createBoardData(requestData).then((res) => {
      if (res.successOrNot === 'Y') {
        history.push('/board-6');
      }
    });
  };

  // 취소
  const handleCancel = () => {
    history.push('/board-6');
  };

  // 카테고리 리스트 가져오기
  useEffect(() => {
    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    void getCategoryList().then((res) => {
      if (res && res.length > 0) {
        setCategoryList(res);
        setSelectedCategory(res[0].codeId);
      }
    });
  }, []);

  return (
    <div id="BoardRegistForm">
      <div className="title">
        <span id="title" data-testid="title">
          나도한마디
        </span>
        <span className="subTitle">등록하기</span>
      </div>
      <Form className="inputForm">
        <Form.Row className="category-row">
          <Row>
            <Col className="title-col">
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="제목을 입력하세요."
                id="inputTitle"
                name="inputTitle"
                data-testid="inputTitle"
                className="inputTitle"
                onChange={handleTitleOnChange}
                value={boardTitle}
                maxLength={40}
              />
            </Col>
            <Col className="categorySelect">
              <Select
                className="category"
                defaultValue=""
                value={categoryList.length ? selectedCategory : ''}
                onChange={handleCategoryOnChange}
              >
                {categoryList.map((category) => {
                  return (
                    <MenuItem key={category.codeId} value={category.codeId}>
                      {category.codeName}
                    </MenuItem>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Form.Row>
        <Form.Row id="contents">
          <Form.Control as="textarea" onChange={handleContentsOnChange} maxLength={200} value={contents} rows={20} />
        </Form.Row>
        <div className="btnArea">
          <Button
            id="registBtn"
            type="button"
            onClick={handleSave}
            disabled={!boardTitle || !contents || !selectedCategory}
          >
            <p>등록</p>
          </Button>
          <Button id="cancelBtn" type="button" onClick={handleCancel}>
            <p>취소</p>
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default BoardRegistForm;
