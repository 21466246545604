import './BoardRegistForm.scss';

import { MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import BoardService from '../../../service/board2/BoardService';
import { UpdateBoardRequest } from '../../../service/board2/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode, SelectItem } from '../../../service/common/model/CommonCode';
import ConfirmModal from '../../common/ConfirmModal';

const BoardEditForm: React.FC = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const boardId = Number(id);
  const history = useHistory();

  const [title, setTitle] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [categories, setCategories] = useState<SelectItem[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const commonService = new CommonCodeService();
    const boardService = new BoardService();

    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    void getCategoryList().then((res) => {
      if (res && res.length > 0) {
        const categories = res.map((commonCode: CommonCode) => {
          return { value: commonCode.codeId, label: commonCode.codeName };
        });
        setCategories(categories);
      }
    });

    void boardService.getBoardDetail(boardId).then((res) => {
      if (res.successOrNot === 'Y') {
        console.log(res);
        setTitle(res.data.boardTitle);
        setCategory(res.data.category);
        setContent(res.data.boardContents);
      }
    });
  }, []);

  const handleCancelBtn = useCallback(() => {
    history.push(`/board-2/detail/${boardId}`);
  }, [history, boardId]);

  const handleSaveBtn = useCallback(() => {
    if (window.confirm('게시글을 수정하시겠습니까?')) {
      const boardService = new BoardService();

      const board: UpdateBoardRequest = {
        boardId,
        boardTitle: title,
        category,
        boardContents: content,
      };

      void boardService.updateBoardData(board).then((res) => {
        if (res.successOrNot === 'Y') {
          history.push(`/board-2/detail/${boardId}`);
        }
      });
    }
  }, [boardId, title, category, content, history]);

  return (
    <div id="BoardEditForm">
      <div className="title">
        <span id="title" data-testid="title">
          나도한마디
        </span>
        <span className="subTitle" style={{ marginLeft: '10px' }}>
          수정하기
        </span>
      </div>
      <Form className="inputForm">
        <Form.Row className="category-row">
          <Row>
            <Col className="title-col" style={{ marginTop: '10px' }}>
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="제목을 입력하세요."
                id="inputTitle"
                name="inputTitle"
                data-testid="inputTitle"
                className="inputTitle"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                maxLength={40}
              />
            </Col>
            <Col className="categorySelect">
              <Select
                defaultValue=""
                className="category"
                value={category}
                onChange={(e) => setCategory(e.target.value as string)}
                placeholder={'선택하세요'}
                style={{ paddingLeft: '10px' }}
              >
                {categories.map((category) => {
                  return (
                    <MenuItem key={category.value} value={category.value}>
                      {category.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Form.Row>
        <Form.Row id="contents">
          <Form.Control
            as="textarea"
            onChange={(e) => setContent(e.target.value)}
            maxLength={200}
            value={content}
            rows={20}
          />
        </Form.Row>
        <div className="btnArea" style={{ marginTop: '15px' }}>
          <Button id="registBtn" type="button" onClick={handleSaveBtn} disabled={!title || !content || !category}>
            <p>등록</p>
          </Button>
          <Button id="cancelBtn" type="button" onClick={() => setShowModal(true)}>
            <p>취소</p>
          </Button>
        </div>
      </Form>
      <ConfirmModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={'게시글 작성을 취소하시겠습니까?'}
        message={'게시글 작성을 취소하시면 작업중인 내용이 모두 초기화됩니다.'}
        confirmLabel={'예, 취소합니다.'}
        cancelLabel={'아니요'}
        onConfirm={handleCancelBtn}
      />
    </div>
  );
};

export default BoardEditForm;
