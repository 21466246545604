import React, { ReactElement } from 'react';

import BoardModifyForm from './component/BoardModifyForm';

const BoardModifyContainer: React.FC = (): ReactElement => {
  return (
    <div id="BoardModifyontainer" data-testid="BoardModifyContainer">
      <BoardModifyForm />
    </div>
  );
};

export default BoardModifyContainer;
