import './MainPage.scss';
import 'rsuite/dist/styles/rsuite-default.css';

import React, { ReactElement, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { Loader } from 'rsuite';

import { AuthContext } from '../../App';
import NavBar from '../../layouts/NavBar';
import SideNavSection from '../../layouts/SideNavSection';
import CommonSpinner from '../../layouts/Spinner';
import BoardPage from '../board/BoardPage';
import BoardPage1 from '../board1/BoardPage';
import BoardPage2 from '../board2/BoardPage';
import BoardPage3 from '../board3/BoardPage';
import BoardPage4 from '../board4/BoardPage';
import BoardPage5 from '../board5/BoardPage';
import BoardPage6 from '../board6/BoardPage';
import BoardPage7 from '../board7/BoardPage';
import BoardPage8 from '../board8/BoardPage';
import BoardPage9 from '../board9/BoardPage';
import BoardPage10 from '../board10/BoardPage';
import BoardPage11 from '../board11/BoardPage';
import BoardPage12 from '../board12/BoardPage';
import BoardPage13 from '../board13/BoardPage';
import BoardPage14 from '../board14/BoardPage';
import BoardPage15 from '../board15/BoardPage';
import BoardPage16 from '../board16/BoardPage';
import BoardPage17 from '../board17/BoardPage';
import ErrorPage from '../error/ErrorPage';
import EventPage from '../event/EventPage';

const MainPage = (): ReactElement => {
  const { state } = useContext(AuthContext);

  return (
    <div id="MainPage">
      {state.common.isLoading && <Loader backdrop center content={<CommonSpinner />} />}
      <Container>
        <Row id="header-row">
          <NavBar />
        </Row>
        <Row id="body-row" className="d-flex">
          <div id="sideNav" className="sideNav-expanded">
            <SideNavSection />
          </div>
          <Col className="body-content">
            <Switch>
              <Route path="/board" component={BoardPage} />
              <Route path="/board-1" component={BoardPage1} />
              <Route path="/board-2" component={BoardPage2} />
              <Route path="/board-3" component={BoardPage3} />
              <Route path="/board-4" component={BoardPage4} />
              <Route path="/board-5" component={BoardPage5} />
              <Route path="/board-6" component={BoardPage6} />
              <Route path="/board-7" component={BoardPage7} />
              <Route path="/board-8" component={BoardPage8} />
              <Route path="/board-9" component={BoardPage9} />
              <Route path="/board-10" component={BoardPage10} />
              <Route path="/board-11" component={BoardPage11} />
              <Route path="/board-12" component={BoardPage12} />
              <Route path="/board-13" component={BoardPage13} />
              <Route path="/board-14" component={BoardPage14} />
              <Route path="/board-15" component={BoardPage15} />
              <Route path="/board-16" component={BoardPage16} />
              <Route path="/board-17" component={BoardPage17} />
              <Route path="/event" component={EventPage} />
              <Route component={ErrorPage} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default MainPage;
