import './BoardDetailContainer.scss';

import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import BoardService from '../../service/board7/BoardService';
import { BoardData } from '../../service/board7/model/Board';
import BoardDetailInfo from './component/BoardDetailInfo';

const BoardDetailContainer: React.FC = (): ReactElement => {
  const boardService = new BoardService();
  const { id } = useParams<{ id: string }>();
  const boardId = Number(id);
  const history = useHistory();

  const [detail, setDetail] = useState<BoardData>();

  useEffect(() => {
    void boardService.getBoardDetail(boardId).then((response) => {
      if (response.successOrNot === 'Y') {
        setDetail(response.data);
      }
    });
  },[]); // eslint-disable-line

  const handleListOnClick = () => {
    history.push('/board-7');
  };

  return (
    <div id="BoardDetailContainer">
      {detail && <BoardDetailInfo detail={detail} />}
      <Row className="btnArea">
        <Button id="deleteBtn" type="button">
          <p>삭제</p>
        </Button>
        <Button id="editBtn" type="button">
          <p>수정</p>
        </Button>
        <Button id="listBtn" type="button" onClick={handleListOnClick}>
          <p>목록</p>
        </Button>
      </Row>
    </div>
  );
};

export default BoardDetailContainer;
