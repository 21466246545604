import './NavBar.scss';

import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import logo from '../icons/common/logo.svg';
import LoginService from '../service/member/login/LoginService';

const NavBar: React.FC = () => {
  const { t } = useTranslation();
  const logoinService = new LoginService();

  const handleLogout = (event) => {
    event.preventDefault();
    void logoinService.requestLogout().then((res) => {
      sessionStorage.clear();
      window.location.assign('/');
    });
  };

  return (
    <div id="navBar">
      <Navbar fixed="top" bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <Image id="logo" src={logo} />
        </Navbar.Brand>
        <Button
          id="logoutButton"
          name="logoutButton"
          className="logout-button"
          onClick={handleLogout}
          data-testid="logoutButton"
          type="submit"
        >
          {t('admin.label.LogoutButton.logout')}
        </Button>
      </Navbar>
    </div>
  );
};

export default NavBar;
