import React, { ReactElement } from 'react';
import { Route } from 'react-router-dom';

import BoardDetailContainer from './BoardDetailContainer';
import BoardListContainer from './BoardListContainer';
import BoardRegistContainer from './BoardRegistContainer';

const BoardPage4 = (): ReactElement => {
  return (
    <>
      <Route exact path="/board-4" component={BoardListContainer}></Route>
      <Route exact path="/board-4/detail/:id" component={BoardDetailContainer}></Route>
      <Route exact path="/board-4/regist" component={BoardRegistContainer} />
    </>
  );
};

export default BoardPage4;
