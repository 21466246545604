import React, { ReactElement } from 'react';

import BoardRegistForm from './component/BoardRegistForm';

const BoardRegistContainer: React.FC = (): ReactElement => {
  return (
    <div id="BoardRegistContainer">
      <BoardRegistForm />
    </div>
  );
};

export default BoardRegistContainer;
