import './BoardListSection.scss';

import MaterialTable, { Column, Options } from 'material-table';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { SelectPicker } from 'rsuite';
import TablePagination from 'rsuite/lib/Table/TablePagination';

import { BoardData, BoardSearchCondition } from '../../../service/board17/model/Board';
import { pageSizeOptions } from '../../../service/common/model/Common';
import { CategoryName } from '../../../service/common/model/CommonCode';
import { CommonDateFormat } from '../../../service/common/model/enum/CommonDateFormat';
import { useWindowDimensions } from '../../../service/common/utils/useWindowDimensions';
import { t } from '../../../service/common/utils/util';

interface Props {
  defaultSearchCondition: BoardSearchCondition;
  searchCondition: BoardSearchCondition;
  onSearchConditionChange: Function;
  boardList: BoardData[];
  totalCount: number;
}

const BoardListSection: React.FC<Props> = (props: Props): ReactElement => {
  const { height } = useWindowDimensions();
  const history = useHistory();

  const handleRowClick = (rowData) => {
    history.push('/board-17/detail/' + rowData.boardId);
  };

  const options: Options<BoardData> = {
    showTitle: false,
    search: false,
    selection: false,
    sorting: false,
    thirdSortClick: false,
    paging: false,
    minBodyHeight: 90,
    maxBodyHeight: height - 280,
    toolbar: false,
  };

  const columns: Array<Column<BoardData>> = [
    {
      title: t('sample.label.FreetalkingBoardListSection.title'),
      field: 'boardTitle',
      headerStyle: {
        minWidth: 480,
        maxWidth: 880,
      },
      cellStyle: {
        minWidth: 480,
        maxWidth: 880,
        textAlign: 'left',
      },
      render: (rowData) => {
        let subStrTitle = rowData.boardTitle || '';
        if (subStrTitle.length > 10) {
          subStrTitle = subStrTitle.substring(0, 50) + '...';
        }
        return subStrTitle;
      },
    },
    {
      title: t('sample.label.FreetalkingBoardListSection.category'),
      field: 'categoryName',
      headerStyle: {
        minWidth: 100,
        maxWidth: 150,
        textAlign: 'center',
      },
      cellStyle: {
        minWidth: 100,
        maxWidth: 150,
        textAlign: 'center',
      },
      render: (rowData) => {
        return CategoryName[rowData.category];
      },
    },
    {
      title: t('sample.label.FreetalkingBoardListSection.writer'),
      field: 'createMemberName',
      headerStyle: {
        minWidth: 140,
        maxWidth: 280,
        textAlign: 'center',
      },
      cellStyle: {
        minWidth: 140,
        maxWidth: 280,
        textAlign: 'center',
      },
    },
    {
      title: t('sample.label.FreetalkingBoardListSection.writeDate'),
      field: 'createdDatetime',
      headerStyle: {
        minWidth: 120,
        maxWidth: 180,
        textAlign: 'center',
      },
      cellStyle: {
        minWidth: 120,
        maxWidth: 180,
        textAlign: 'center',
      },
      render: (rowData) => {
        return rowData.createdDatetime ? moment(rowData.createdDatetime).format(CommonDateFormat.DATE_DISP) : '';
      },
    },
  ];

  const selectPicker = pageSizeOptions.map((value) => {
    return { value: value, label: value + t('common.label.pagingLabel') };
  });

  return (
    <div id="BoardListSection">
      <div id={'MaterialTableWrapper'} className={'MaterialTableWrapper'}>
        <MaterialTable
          data-testid={'boardTable'}
          options={options}
          columns={columns}
          data={props.boardList}
          onRowClick={(evt, rowData) => handleRowClick(rowData)}
          localization={{
            body: { emptyDataSourceMessage: t('sample.message.FreetalkingListSection.noData') },
          }}
        />
      </div>
      <div id="BoardListSectionFooter">
        <TablePagination
          style={{ float: 'left' }}
          showInfo={false}
          renderLengthMenu={() => {
            return (
              <SelectPicker
                data={selectPicker}
                valueKey={'value'}
                menuClassName="pagination-select-menu"
                value={props.searchCondition.pageSize}
                cleanable={false}
                searchable={false}
                placement={'topStart'}
                onChange={(pageSize: number) => {
                  props.onSearchConditionChange({ ...props.searchCondition, pageSize: pageSize });
                }}
              />
            );
          }}
          activePage={props.searchCondition.pageIndex + 1}
          displayLength={props.searchCondition.pageSize || 15}
          total={props.totalCount}
          ellipsis={true}
          boundaryLinks={true}
          onChangePage={(pageNumber: number) => {
            props.onSearchConditionChange({ ...props.searchCondition, pageIndex: pageNumber - 1 });
          }}
        />
        <Button id="registButton" type="button" className="btn-regist"></Button>
      </div>
    </div>
  );
};

export default BoardListSection;
