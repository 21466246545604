import './BoardDetailContainer.scss';

import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import BoardService from '../../service/board8/BoardService';
import { BoardData } from '../../service/board8/model/Board';
import SessionService from '../../service/SessionService';
import ConfirmModal from '../common/ConfirmModal';
import BoardDetailInfo from './component/BoardDetailInfo';

const BoardDetailContainer: React.FC = (): ReactElement => {
  const sessionService = new SessionService();
  const boardService = new BoardService();

  const { id } = useParams<{ id: string }>();
  const boardId = Number(id);
  const history = useHistory();

  const [detail, setDetail] = useState<BoardData>();
  const [isWriter, setIsWriter] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    void boardService.getBoardDetail(boardId).then((response) => {
      if (response.successOrNot === 'Y') {
        setDetail(response.data);
        const loggedInUserName = sessionService.getSessionInfo().memberName;
        if (response.data.createMemberName === loggedInUserName) {
          setIsWriter(true);
        }
      }
    });
  },[]); // eslint-disable-line

  const handleDeleteOnClick = () => {
    if (!isWriter) return;
    void boardService.deleteBoardData(Number(id)).then((response) => {
      if (response.successOrNot === 'Y') {
        history.push('/board-8');
      }
    });
  };

  const handleUpdateOnClick = () => {
    history.push(`/board-8/regist`, { id: id });
  };

  const handleListOnClick = () => {
    history.push('/board-8');
  };

  return (
    <>
      <div id="BoardDetailContainer">
        {detail && <BoardDetailInfo detail={detail} />}
        <Row className="btnArea">
          {isWriter && (
            <Button id="deleteBtn" type="button" onClick={() => setModalOpen(true)}>
              <p>삭제</p>
            </Button>
          )}
          <Button id="editBtn" type="button" onClick={handleUpdateOnClick}>
            <p>수정</p>
          </Button>
          <Button id="listBtn" type="button" onClick={handleListOnClick}>
            <p>목록</p>
          </Button>
        </Row>
        <ConfirmModal
          show={modalOpen}
          onHide={() => setModalOpen(false)}
          title={'게시글을 삭제하시겠습니까?'}
          message={'한 번 삭제한 게시글은 복구 할 수 없습니다.'}
          confirmLabel={'예, 삭제합니다.'}
          cancelLabel={'아니요'}
          onConfirm={handleDeleteOnClick}
        />
      </div>
    </>
  );
};

export default BoardDetailContainer;
