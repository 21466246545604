import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import BoardRegistForm from './component/BoardRegistForm';

interface LocationState {
  id?: string;
}

const BoardRegistContainer: React.FC = (): ReactElement => {
  const location = useLocation<LocationState>();
  const id = location.state?.id;

  return (
    <div id="BoardRegistContainer">
      <BoardRegistForm id={id} />
    </div>
  );
};

export default BoardRegistContainer;
