import React from 'react';

import BoardEditForm from './component/BoardEditForm';

function BoardEditContainer() {
  return (
    <div id="BoardEditContainer" data-testid="BoardEditContainer">
      <BoardEditForm />
    </div>
  );
}

export default BoardEditContainer;
