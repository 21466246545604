import './BoardRegistForm.scss';

import { MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import BoardService from '../../../service/board8/BoardService';
import { RegistBoardRequest } from '../../../service/board8/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode } from '../../../service/common/model/CommonCode';
import ConfirmModal from '../../common/ConfirmModal';

/* eslint-disable */
interface BoardRegistFormProps {
  id?: string;
}

const BoardRegistForm: React.FC<BoardRegistFormProps> = ({ id }): ReactElement => {
  const history = useHistory();

  // 컴포넌트에 서비스 객체를 만들고 컴포넌트가 만들어질때 데이터를 가져오게끔 처리합니다.
  const commonService = new CommonCodeService();
  const boardService = new BoardService();

  const [categoryList, setCategoryList] = useState<CommonCode[]>([]);
  const [boardTitle, setBoardTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [contents, setContents] = useState('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleTitleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBoardTitle(event.target.value);
  };

  const handleCategoryOnChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleContentsOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setContents(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const requestData: RegistBoardRequest = {
      boardTitle: boardTitle,
      category: selectedCategory,
      boardContents: contents,
    };

    if (!id) {
      // 등록
      createBoardData(requestData);
    } else {
      //수정
      updateBoardData({ ...requestData, id: id });
    }

    
  };

  const createBoardData = (requestData: RegistBoardRequest) => {
    void boardService.createBoardData(requestData).then((response) => {
      if (response.successOrNot === 'Y') {
        history.push('/board-8');
      }
    });
  };

  const updateBoardData = (requestData: RegistBoardRequest) => {
    void boardService.updateBoardData(requestData).then((response) => {
      if (response.successOrNot === 'Y') {
        history.push('/board-8');
      }
    });
  };

  const handleCancel = () => {
    history.push(`/board-8`);
  };

  useEffect(() => {
    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    void getCategoryList().then((res) => {
      if (res && res.length > 0) {
        setSelectedCategory(res[0].codeId);
        setCategoryList(res);
      }
    });

    if(id !== undefined){
      const getBoardDetail = async () => {
        return await commonService.getCommonCode(GroupCode.CATEGORY);
      };

      void boardService.getBoardDetail(Number(id)).then((response) => {
        if (response && response.successOrNot === 'Y') {          
          setBoardTitle(response.data.boardTitle);
          setSelectedCategory(response.data.category);
          setContents(response.data.boardContents);
        }
      });
    }
  }, []);

  return (
    <div id="BoardRegistForm">
      <div className="title">
        <span id="title" data-testid="title">
          나도한마디
        </span>
        <span className="subTitle">{!id ? `등록하기` : `수정하기`}</span>
        <Form className="inputForm">
          <Form.Row className="category-row">
            <Row>
              <Col className="title-col">
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="제목을 입력하세요."
                  id="inputTitle"
                  name="inputTitle"
                  data-testid="inputTitle"
                  className="inputTitle"
                  onChange={handleTitleOnChange}
                  value={boardTitle}
                  maxLength={40}
                />
              </Col>
              <Col className="categorySelect">
                <Select
                  className="category"
                  defaultValue=""
                  placeholder="카테고리."
                  value={selectedCategory}
                  onChange={handleCategoryOnChange}
                >
                  {categoryList.map((category) => {
                    return (
                      <MenuItem key={category.codeId} value={category.codeId}>
                        {category.codeName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </Form.Row>
          <Form.Row id="contents">
            <Form.Control as="textarea" onChange={handleContentsOnChange} maxLength={200} value={contents} rows={20} />
          </Form.Row>
          <div className="btnArea">
            <Button
              id="registBtn"
              type="button"
              onClick={handleSubmit}
              disabled={!boardTitle || !contents || !selectedCategory}
            >
              <p>{!id ? `등록` : `저장`}</p>
            </Button>
            <Button id="cancelBtn" type="button" onClick={() => setModalOpen(true)}>
              <p>취소</p>
            </Button>
          </div>
        </Form>
        <ConfirmModal
          show={modalOpen}
          onHide={() => setModalOpen(false)}
          title={'게시글 작성을 취소하시겠습니까?'}
          message={'게시글 작성을 취소하시면 작업중인 내용이 모두 초기화됩니다.'}
          confirmLabel={'예, 취소합니다.'}
          cancelLabel={'아니요'}
          onConfirm={handleCancel}
        />
      </div>
    </div>
  );
};

export default BoardRegistForm;
