import './BoardListSearchSection.scss';

import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Avatar, Button } from 'rsuite';
import { ItemDataType } from 'rsuite/lib/@types/common';
import { ValueType } from 'rsuite/lib/DateRangePicker';

import { BoardSearchCondition } from '../../../service/board5/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode } from '../../../service/common/model/CommonCode';
import { CommonDateFormat } from '../../../service/common/model/enum/CommonDateFormat';
import { DateRangeTypes } from '../../../service/common/model/enum/DateRange.enum';
import { PickerItem } from '../../../service/common/model/PickerItem.model';
import CommonCheckPicker from '../../common/CommonCheckPicker';
import CommonDateRangePicker from '../../common/CommonDateRangePicker';
import CommonEnterSearchBar from '../../common/CommonEnterSearchBar';

interface Props {
  defaultSearchCondition: BoardSearchCondition;
  searchCondition: BoardSearchCondition;
  onSearchConditionChange: Function;
  totalCount: number;
}

const BoardListSearchSection: React.FC<Props> = (props: Props): ReactElement => {
  const { t } = useTranslation();

  const commonService = new CommonCodeService();

  const defaultRange: ValueType = [
    moment(props.defaultSearchCondition.startDate).toDate(),
    moment(props.defaultSearchCondition.endDate).toDate(),
  ]; // 최근 1주일

  const defaultCheckPickerItem = [{ label: t('common.label.ALL'), value: 'ALL' }];
  const [dateRange, setDateRange] = useState<ValueType>(defaultRange);
  const [categoryList, setCategoryList] = useState<Array<PickerItem>>(defaultCheckPickerItem);
  let changeCondition: BoardSearchCondition;

  const setDateRangePicker = (date: ValueType) => {
    setDateRange(date);
    changeCondition = {
      ...props.searchCondition,
      startDate: moment(date[0]).format(CommonDateFormat.DATE_IF),
      endDate: moment(date[1]).format(CommonDateFormat.DATE_IF),
      pageIndex: 0,
    };
    props.onSearchConditionChange(changeCondition);
  };

  const handleTitleKeyPressed = (value: string) => {
    if (value.length >= 2) {
      changeCondition = {
        ...props.searchCondition,
        boardTitle: value.trim(),
        pageIndex: 0,
      };
      props.onSearchConditionChange(changeCondition);
    } else if (value.length === 0) {
      handleTitleCleared();
    }
  };

  const handleTitleCleared = () => {
    changeCondition = {
      ...props.searchCondition,
      boardTitle: '',
      pageIndex: 0,
    };
    props.onSearchConditionChange(changeCondition);
  };

  const handleCategorySelect = (items: Array<string>) => {
    changeCondition = {
      ...props.searchCondition,
      category: items.includes('ALL') ? [] : items,
      pageIndex: 0,
    };
    props.onSearchConditionChange(changeCondition);
  };

  const handleCategoryClean = () => {
    changeCondition = {
      ...props.searchCondition,
      category: [],
      pageIndex: 0,
    };
    props.onSearchConditionChange(changeCondition);
  };

  const handleRenderValue = (
    value: any,
    item: ItemDataType[],
    selectedElement: React.ReactNode,
    id?: string,
    placeholder?: string
  ) => {
    return (
      <div id={id}>
        <p className="label">{placeholder}</p>
        {item.findIndex((i) => i.value === 'ALL') < 0 ? (
          selectedElement
        ) : (
          <span className="rs-picker-value-list">
            <span className="rs-picker-value-item">{t('common.label.ALL')}</span>
          </span>
        )}
      </div>
    );
  };

  const initCondition = () => {
    setDateRange(defaultRange);
    props.onSearchConditionChange(props.defaultSearchCondition);
  };

  const convertCommonCodeToPicker = (commonCodeList: CommonCode[]) => {
    return commonCodeList.map((item) => {
      return {
        label: item.codeName,
        value: item.codeId,
      };
    });
  };

  useEffect(() => {
    void commonService.getCommonCode(GroupCode.CATEGORY).then((response) => {
      const itemList = convertCommonCodeToPicker(response);
      setCategoryList(categoryList.concat(itemList));
    });
  }, []); // eslint-disable-line

  return (
    <div id="BoardListSearchSection">
      <Row>
        <Col>
          <span id="title" data-testid="title">
            {t('sample.label.FreetalkingBoardSearchSection.title')}
          </span>
        </Col>
        <Col className="s-date">
          {/* 등록일 */}
          <CommonDateRangePicker
            id="search-requestDate"
            data-testid="search-requestDate"
            placement="bottomStart"
            placeholder={t('sample.label.FreetalkingBoardSearchSection.writeDate')}
            dateRange={dateRange}
            setDateRangePicker={setDateRangePicker}
            selectAvailableRange={DateRangeTypes.TWO_YEARS}
            closeOverlay={false}
          />
        </Col>
        <Col>
          {/* 제목 */}
          <CommonEnterSearchBar
            id="search-title"
            placeholder={t('sample.label.FreetalkingBoardSearchSection.placeholder.title')}
            onSelect={handleTitleKeyPressed}
            initialValue={props.searchCondition.boardTitle || ''}
            clearable={true}
            onClear={handleTitleCleared}
            lengthLimit={2}
          />
        </Col>
        <Col>
          {/* 카테고리 */}
          <CommonCheckPicker
            id="search-category"
            data-testid="search-category"
            onSelect={handleCategorySelect}
            onClean={handleCategoryClean}
            renderValue={handleRenderValue}
            initialValue={props.searchCondition?.category || []}
            data={categoryList}
            selectAllKey="ALL"
            placeholder={t('sample.label.FreetalkingBoardSearchSection.category')}
            footerLabel={t('common.label.apply')}
          />
        </Col>
        <Col>
          {/* 초기화 */}
          <Button
            id="search-init"
            className="btn-refresh"
            data-testid="search-init"
            variant="link"
            onClick={initCondition}
          >
            {t('sample.label.FreetalkingBoardSearchSection.init')}
          </Button>
        </Col>
        <Col className="total-count">
          <Avatar circle id="search-totalCount" data-testid="search-totalCount">
            {(props.totalCount?.toLocaleString() || 0) + t('sample.label.FreetalkingBoardSearchSection.counts')}
          </Avatar>
        </Col>
      </Row>
    </div>
  );
};

export default BoardListSearchSection;
