import './MemberRegistrationContainer.scss';

import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../../App';
import CommonLoadingOverlay from '../../../layouts/LoadingOverlay';
import StepInformation from '../../../service/member/memberRegistration/model/StepInformation';
import MainSection from './component/MainSection';

const MemberRegistrationContainer = (): ReactElement => {
  const { state } = useContext(AuthContext);
  const [stepParams, setStepParams] = useState<StepInformation>({
    currentStep: 1,
  });

  const isLoadingReducer: boolean = state.common.isLoading ? true : false;
  const currentStep: number | undefined = stepParams.currentStep;
  const history = useHistory();

  /* eslint-disable */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    const location = history.location;

    if (currentStep) {
      history.replace(location.pathname + location.search + '#step' + currentStep);
    }
  }, []);

  useEffect(() => {
    const location = history.location;
    if (location.hash.includes('#step1')) {
      setStepParams({ ...stepParams, currentStep: 1 });
    }
    if (location.hash.includes('#step2')) {
      setStepParams({ ...stepParams, currentStep: 2 });
    }
  }, [history.location]);
  /* eslint-enable */

  const getMemberContainerBody = () => {
    return (
      <div id="MemberContainer">
        {currentStep && (
          <Container fluid={true}>
            <Row>
              <Col className="main-side">
                <div className="main-content-area">
                  <MainSection setStepParams={setStepParams} stepParams={stepParams} />
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  };
  return <CommonLoadingOverlay active={isLoadingReducer}>{getMemberContainerBody}</CommonLoadingOverlay>;
};

export default MemberRegistrationContainer;
