import React, { ReactElement } from 'react';
import { Route } from 'react-router-dom';

import BoardDetailContainer from './BoardDetailContainer';
import BoardListContainer from './BoardListContainer';
import BoardRegistContainer from './BoardRegistContainer';

const BoardPage8 = (): ReactElement => {
  return (
    <>
      <Route exact path="/board-8" component={BoardListContainer} />
      <Route exact path="/board-8/detail/:id" component={BoardDetailContainer} />
      <Route exact path="/board-8/regist" component={BoardRegistContainer} />
    </>
  );
};

export default BoardPage8;
