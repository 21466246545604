import './BoardListContainer.scss';

import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import BoardService from '../../service/board16/BoardService';
import { BoardData, BoardSearchCondition } from '../../service/board16/model/Board';
import { CommonDateFormat } from '../../service/common/model/enum/CommonDateFormat';
import BoardListSearchSection from './component/BoardListSearchSection';
import BoardListSection from './component/BoardListSection';
import BoardRegistForm from './component/BoardRegistForm';

const BoardRegistContainer: React.FC = (): ReactElement => {
  return (
    <div id="BoardRegistContainer">
      <BoardRegistForm />
    </div>
  );
};

export default BoardRegistContainer;
