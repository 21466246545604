import './BoardListContainer.scss';

import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import BoardService from '../../service/board8/BoardService';
import { BoardData, BoardSearchCondition } from '../../service/board8/model/Board';
import { CommonDateFormat } from '../../service/common/model/enum/CommonDateFormat';
import BoardListSearchSection from './component/BoardListSearchSection';
import BoardListSection from './component/BoardListSection';

const BoardListContainer: React.FC = (): ReactElement => {
  const boardService = new BoardService();
  const [boardList, setBoardList] = useState<BoardData[]>([]);

  const defaultSearchCondition = {
    pageIndex: 0,
    pageSize: 15,
    category: [],
    startDate: moment().subtract(1, 'weeks').add(1, 'day').format(CommonDateFormat.DATE_IF),
    endDate: moment().format(CommonDateFormat.DATE_IF),
  };
  const [searchCondition, setSearchCondition] = useState<BoardSearchCondition>(defaultSearchCondition);
  const [totalCount, setTotalCount] = useState<number>(0);
  const handleCondition = (condition: BoardSearchCondition) => {
    setSearchCondition(condition);
  };

  useEffect(() => {
    void boardService.getBoardList(searchCondition).then((response) => {
      if (response && response.successOrNot === 'Y') {
        setTotalCount(response.data.totalCount);
        setBoardList(response.data.boardList);
      }
    });
  }, [searchCondition]); // eslint-disable-line

  return (
    <div id="BoardListContainer">
      <Row className="search">
        <BoardListSearchSection
          defaultSearchCondition={defaultSearchCondition}
          searchCondition={searchCondition}
          onSearchConditionChange={handleCondition}
          totalCount={totalCount}
        />
      </Row>
      <Row>
        <BoardListSection
          defaultSearchCondition={defaultSearchCondition}
          searchCondition={searchCondition}
          onSearchConditionChange={handleCondition}
          boardList={boardList}
          totalCount={totalCount}
        />
      </Row>
    </div>
  );
};

export default BoardListContainer;
