import './BoardRegistForm.scss';

import { MenuItem, Select } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode } from '../../../service/common/model/CommonCode';

const BoardRegistForm = () => {
  const history = useHistory();

  const [boardTitle, setBoardTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('1');
  const [contents, setContents] = useState('');
  const [categoryList, setCategoryList] = useState<CommonCode[]>([]);

  const commonService = new CommonCodeService();

  const handleTitleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBoardTitle(event.target.value);
  };

  const handleCategoryOnChange = (event): void => {
    setSelectedCategory(event.target.value);
  };

  const handleContentsOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setContents(event.target.value);
  };

  const handleRegistOnClick = () => {
    console.log(`Title: ${boardTitle}`);
    console.log(`Category: ${selectedCategory}`);
    console.log(`Contents: ${contents}`);

    history.push('/board-7');
  };

  const handleCancelOnClick = () => {
    history.push('/board-7');
  };

  useEffect(() => {
    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    void getCategoryList().then((res) => {
      if (res && res.length > 0) {
        setCategoryList(res);
        setSelectedCategory(res[0].codeId);
      }
    });
  }, []);
  return (
    <>
      <div id="BoardRegistForm">
        <div className="title">
          <span id="title" data-testid="title">
            나도 한마디
          </span>
          <span className="subTitle">등록하기</span>
        </div>

        <Form className="inputForm">
          <Form.Row className="category-row">
            <Row>
              <Col className="title-col">
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="제목을 입력하세요."
                  id="inputTitle"
                  name="inputTitle"
                  data-testid="inputTitle"
                  className="inputTitle"
                  onChange={handleTitleOnChange}
                  value={boardTitle}
                  maxLength={40}
                />
              </Col>
              <Col className="categorySelect">
                <Select className="category" defaultValue="" value={selectedCategory} onChange={handleCategoryOnChange}>
                  {categoryList.map((category, i) => (
                    <MenuItem key={category.codeId} value={category.codeId}>
                      {category.codeName}
                    </MenuItem>
                  ))}
                </Select>
              </Col>
            </Row>
          </Form.Row>
          <Form.Row id="contents">
            <Form.Control as="textarea" onChange={handleContentsOnChange} maxLength={200} value={contents} rows={20} />
          </Form.Row>
          <div className="btnArea">
            <Button
              id="registBtn"
              type="button"
              disabled={!boardTitle || !contents || !selectedCategory}
              onClick={handleRegistOnClick}
            >
              <p>등록</p>
            </Button>
            <Button id="cancelBtn" type="button" onClick={handleCancelOnClick}>
              <p>취소</p>
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default BoardRegistForm;
