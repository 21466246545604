import './TermsAcception.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../../../App';
import { CommonActionType } from '../../../../reducer/actions';
import StepInformation from '../../../../service/member/memberRegistration/model/StepInformation';
import TermAgreeInfo from '../../../../service/member/memberRegistration/model/TermAgreeInfo';
import TermCheckboxStatus from '../../../../service/member/memberRegistration/model/TermCheckboxStatus';
import TermsInfo from '../../../../service/member/memberRegistration/model/TermsInfo';
import TermService from '../../../../service/member/memberRegistration/TermService';
import TermsAcceptionModal from './TermsAcceptionModal';

export interface TermsAcceptionProps {
  setStepParams: (stepParams: StepInformation) => void;
  stepParams: StepInformation;
  termService: TermService;
}
export const TermsAcception: React.FC<TermsAcceptionProps> = (props: TermsAcceptionProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);

  const [APICalled, setAPICalled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedTerm, setSelectedTerm] = useState<TermCheckboxStatus>();
  const [terms, setTerms] = useState<TermCheckboxStatus[]>([]);

  const isChecked = (checkBox: TermCheckboxStatus) => checkBox.checked;
  const isRequiredAndChecked = (checkBox: TermCheckboxStatus) =>
    !checkBox.required || (checkBox.required && checkBox.checked);
  let stepParams: StepInformation;

  const createTermAgreeInfo = (): TermAgreeInfo => {
    return {
      termsAgree: terms.map((term: TermCheckboxStatus) => {
        return {
          termsId: term.termsId,
          termsAgreeYn: term.checked ? 'Y' : 'N',
        };
      }),
    };
  };

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: CommonActionType.LOADING, loading: { isLoading: _isLoading } });
  };

  /* eslint-disable */
  useEffect(() => {
    const fetchTermData = async () => {
      return await props.termService.requestTermInfo();
    };
    setLoading(true);
    fetchTermData()
      .then(res => {
        const response = JSON.parse(JSON.stringify(res));

        if (response.successOrNot === 'N') {
          alert(t('admin.message.backEnd.UE'));
        } else {
          if (response.data !== undefined && response.data.length > 0) {
            const tempTerms = response.data.map((dat: TermsInfo) => {
              return {
                checked: false,
                required: dat.mandatoryTermsYn === 'Y',
                termsTitle: dat.termsTitle,
                termsContents: dat.termsContents,
                termsDetailContents: dat.termsDetailContents,
                termsId: dat.termsId,
              };
            });
            setAPICalled(true);
            setTerms(tempTerms);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValidated(true);
    stepParams = {
      currentStep: 2,
      termAgreeInfo: createTermAgreeInfo(),
    };
    props.setStepParams(stepParams);
    history.push(history.location.pathname + history.location.search + '#step2');
  };

  const showModals = (term: TermCheckboxStatus) => {
    setSelectedTerm(term);
    setShowModal(true);
  };

  return (
    <div id="TermsAcception">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {terms.length > 1 && (
          <div>
            <Row className="top-empty-space">
              <Col sm={11}>
                <Form.Check
                  custom
                  id="termAllAgree"
                  type="checkbox"
                  name="termAllAgree"
                  data-testid="termAllAgree"
                  label={t('admin.label.TermsAcception.termLabel.termAllAgree')}
                  className="bold"
                  disabled={!APICalled}
                  checked={APICalled && terms.every(isChecked)}
                  onChange={() => {
                    const asIs: boolean = terms.every(isChecked);
                    const modifiedCheckBoxStatus: TermCheckboxStatus[] = terms.map(termDetail => ({
                      ...termDetail,
                      ...{ checked: !asIs },
                    }));
                    setTerms(modifiedCheckBoxStatus);
                  }}
                />
              </Col>
              <Col sm={1} />
            </Row>
            <Row className="top-empty-space bottom-empty-space left-empty-space">
              <Col sm={11}>
                <span id="termAllAgreeDesc" data-testid="termAllAgreeDesc" className="term-desc">
                  {t('admin.label.TermsAcception.termDesc.termAllAgree')}
                </span>
              </Col>
              <Col sm={1}></Col>
            </Row>
            <Row className="seperation-line"></Row>
          </div>
        )}
        {terms.map((term: TermCheckboxStatus, i) => {
          return (
            <div key={i}>
              <Row className="top-empty-space">
                <Col sm={11}>
                  <Form.Check
                    required={term.required}
                    custom
                    id={String(term.termsId)}
                    type="checkbox"
                    name={String(term.termsId)}
                    data-testid={String(term.termsId)}
                    label={
                      term.required
                        ? t('admin.label.TermsAcception.Label.required') + term.termsTitle
                        : t('admin.label.TermsAcception.Label.notRequired') + term.termsTitle
                    }
                    checked={term.checked}
                    onChange={() => {
                      const modifiedCheckBoxStatus: TermCheckboxStatus[] = terms.map(eachTerm => {
                        return eachTerm.termsId === term.termsId
                          ? {
                              ...eachTerm,
                              ...{ checked: !eachTerm.checked },
                            }
                          : eachTerm;
                      });
                      setTerms(modifiedCheckBoxStatus);
                    }}
                  />
                </Col>
                {term.termsDetailContents && (
                  <Col sm={1}>
                    <i
                      id="firstRightArrow"
                      className="material-icons term-detail-icon"
                      onClick={() => {
                        showModals(term);
                      }}
                    >
                      keyboard_arrow_right
                    </i>
                  </Col>
                )}
              </Row>
              {term.termsContents !== null && (
                <Row className="left-empty-space">
                  <Col sm={11}>
                    <span
                      id={String(term.termsId) + 'Desc'}
                      data-testid={String(term.termsId) + 'Desc'}
                      className="term-desc"
                    >
                      {term.termsContents}
                    </span>
                  </Col>
                  <Col sm={1}></Col>
                </Row>
              )}
            </div>
          );
        })}
        <Row className="nse-agree-btn-row">
          <Button
            id="agreeButton"
            name="agreeButton"
            data-testid="agreeButton"
            className="agree-button"
            type="submit"
            disabled={!APICalled || !terms.every(isRequiredAndChecked)}
          >
            {t('admin.label.TermsAcception.Label.agree')}
          </Button>
        </Row>
        {showModal && selectedTerm && <TermsAcceptionModal term={selectedTerm} onHide={() => setShowModal(false)} />}
      </Form>
    </div>
  );
};
