import i18next from 'i18next';
import moment from 'moment';
import DateRangePicker, { RangeType } from 'rsuite/lib/DateRangePicker';
import localeKo from 'rsuite/lib/IntlProvider/locales/ko_KR';

import { CommonDateFormat } from '../model/enum/CommonDateFormat';
import { DateRangeTypes } from '../model/enum/DateRange.enum';
import { SchemaCheckResult } from '../model/SchemaCheckResult';

export const t = (code: string, params?: object): string => {
  if (params) {
    return i18next.t(code, params);
  } else {
    return i18next.t(code);
  }
};

export const isObject = (value: any): boolean => {
  return value !== null && typeof value === 'object';
};

export const isString = (value: any): boolean => {
  return typeof value === 'string';
};

export const deepMerge = (o1: Record<string, any>, o2: Record<string, any>): Record<string, any> => {
  const keys = Object.keys(o2);
  // eslint-disable-next-line
  for (const key in keys) {
    if (isObject(o2[keys[key]]) && o1[keys[key]]) {
      o1[keys[key]] = deepMerge(o1[keys[key]], o2[keys[key]]);
    } else {
      o1[keys[key]] = o2[keys[key]];
    }
  }
  return o1;
};

export const deepTrim = (o: any): any => {
  if (isObject(o)) {
    const keys = Object.keys(o);
    // eslint-disable-next-line
    for (const key in keys) {
      o[keys[key]] = deepTrim(o[keys[key]]);
    }
  } else if (isString(o)) {
    o = o.trim();
  }
  return o;
};

export const getNullToEmpty = (str: string | undefined): string => {
  if (str === undefined || str === null) {
    return '';
  } else {
    return str;
  }
};

export const getUtcTimestampToLocal = (timestamp: number | string, format: CommonDateFormat) => {
  return moment(Number(timestamp)).format(format);
};

export const getDateRangeOption = (dateRangeType: DateRangeTypes, isPast = true, closeOverlay = false): RangeType => {
  if (isPast) {
    switch (dateRangeType) {
      case DateRangeTypes.TODAY:
        return {
          label: 'today',
          value: [moment(new Date()).startOf('day').toDate(), moment(new Date()).endOf('day').toDate()],
          closeOverlay: closeOverlay,
        };

      case DateRangeTypes.ONE_WEEK:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.oneWeek'),
          value: [moment(new Date()).subtract(1, 'weeks').add(1, 'day').toDate(), moment(new Date()).toDate()],
          closeOverlay: closeOverlay,
        };
      case DateRangeTypes.TWO_WEEKS:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.twoWeeks'),
          value: [moment(new Date()).subtract(2, 'weeks').add(1, 'day').toDate(), moment(new Date()).toDate()],
          closeOverlay: closeOverlay,
        };
      case DateRangeTypes.ONE_MONTH:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.oneMonth'),
          value: [moment(new Date()).subtract(1, 'months').add(1, 'day').toDate(), moment(new Date()).toDate()],
          closeOverlay: closeOverlay,
        };
      case DateRangeTypes.THREE_MONTHS:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.threeMonths'),
          value: [moment(new Date()).subtract(3, 'months').add(1, 'day').toDate(), moment(new Date()).toDate()],
          closeOverlay: closeOverlay,
        };
      case DateRangeTypes.ONE_YEAR:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.oneYear'),
          value: [moment(new Date()).subtract(1, 'years').add(1, 'day').toDate(), moment(new Date()).toDate()],
          closeOverlay: closeOverlay,
        };
      case DateRangeTypes.TWO_YEARS:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.twoYears'),
          value: [moment(new Date()).subtract(2, 'years').add(1, 'day').toDate(), moment(new Date()).toDate()],
          closeOverlay: closeOverlay,
        };
      default:
        return { label: '', value: [], closeOverlay: closeOverlay };
    }
  } else {
    switch (dateRangeType) {
      case DateRangeTypes.TODAY:
        return {
          label: 'today',
          value: [moment(new Date()).startOf('day').toDate(), moment(new Date()).endOf('day').toDate()],
          closeOverlay: closeOverlay,
        };

      case DateRangeTypes.ONE_WEEK:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.oneWeek'),
          value: [moment(new Date()).toDate(), moment(new Date()).add(1, 'weeks').toDate()],
          closeOverlay: closeOverlay,
        };
      case DateRangeTypes.TWO_WEEKS:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.twoWeeks'),
          value: [moment(new Date()).toDate(), moment(new Date()).add(2, 'weeks').toDate()],
          closeOverlay: closeOverlay,
        };
      case DateRangeTypes.ONE_MONTH:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.oneMonth'),
          value: [moment(new Date()).toDate(), moment(new Date()).add(1, 'months').toDate()],
          closeOverlay: closeOverlay,
        };
      case DateRangeTypes.THREE_MONTHS:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.threeMonths'),
          value: [moment(new Date()).toDate(), moment(new Date()).add(3, 'months').toDate()],
          closeOverlay: closeOverlay,
        };
      case DateRangeTypes.ONE_YEAR:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.oneYear'),
          value: [moment(new Date()).toDate(), moment(new Date()).add(1, 'years').toDate()],
          closeOverlay: closeOverlay,
        };
      case DateRangeTypes.TWO_YEARS:
        return {
          label: t('common.label.CommonDateRangePicker.dateRangePicker.twoYears'),
          value: [moment(new Date()).toDate(), moment(new Date()).add(2, 'years').toDate()],
          closeOverlay: closeOverlay,
        };
      default:
        return { label: '', value: [], closeOverlay: closeOverlay };
    }
  }
};

const defaultRangeOptions: DateRangeTypes[] = [
  DateRangeTypes.TODAY,
  DateRangeTypes.ONE_WEEK,
  DateRangeTypes.TWO_WEEKS,
  DateRangeTypes.ONE_MONTH,
  DateRangeTypes.THREE_MONTHS,
  DateRangeTypes.ONE_YEAR,
];

export const getDateRangeOptions = (
  closeOverlay = true,
  target: DateRangeTypes[] = defaultRangeOptions,
  isPast = true
): RangeType[] => {
  return target.map((value) => {
    return getDateRangeOption(value, isPast, closeOverlay);
  });
};

const { allowedRange } = DateRangePicker;
export const getDisabledRange = (target?: DateRangeTypes, isPast = true): any => {
  if (target) {
    const dateRange = getDateRangeOption(target, isPast);
    if (dateRange.value.length === 2) {
      return allowedRange(dateRange.value[0], dateRange.value[1]);
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getCustomDateLocale = () => {
  const Locale = {
    DateRangePicker: {
      ok: t('common.label.CommonDateRangePicker.dateRangePicker.ok'),
    },
    DatePicker: {
      ok: t('common.label.CommonDateRangePicker.dateRangePicker.ok'),
    },
  };
  return deepMerge(localeKo, Locale);
};

export const byteToSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Bytes';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

export const isSchemaValid = (checkResult: SchemaCheckResult): boolean => {
  let isValid = true;
  Object.values(checkResult).forEach((value) => {
    if (value.hasError) {
      isValid = false;
    }
  });
  return isValid;
};

export const toPhoneNumberFormat = (phoneStr: string): string => {
  let result = phoneStr;

  if (!phoneStr) {
    return result;
  } else {
    if (phoneStr.length === 10) {
      result = phoneStr.substring(0, 3) + '-' + phoneStr.substring(3, 6) + '-' + phoneStr.substring(6, 10);
    } else if (phoneStr.length > 10) {
      result = phoneStr.substring(0, 3) + '-' + phoneStr.substring(3, 7) + '-' + phoneStr.substring(7, 11);
    }
    return result;
  }
};

export const isValidUrl = (urlStr: string): boolean => {
  const urlTest = /^(\/[a-zA-Z0-9-]+){1,}$/;

  return urlTest.test(urlStr);
};

export const isNumberString = (numberStr: string): boolean => {
  const numberTest = /^[0-9]+$/;

  return numberTest.test(numberStr);
};

export const maskingEmail = (emailStr: string | null): string => {
  if (emailStr === undefined || emailStr === null || emailStr === '') {
    return '';
  } else {
    return emailStr.toString().replace(new RegExp('.(?=.{0,' + 2 + '}@)', 'g'), '*');
  }
};

export const maskingName = (nameStr: string): string => {
  let maskingStr;

  if (nameStr === undefined || nameStr === null || nameStr === '') {
    return '';
  } else {
    if (nameStr.length < 3) {
      maskingStr = nameStr.replace(/(?<=.{1})./g, '*');
    } else {
      maskingStr = nameStr.replace(/(?<!^).(?!$)/g, '*');
    }

    return maskingStr;
  }
};

export const maskingPhoneNumber = (originStr: string): string => {
  let result = originStr;
  let phoneStr;

  if (!originStr) {
    return result;
  } else {
    phoneStr = originStr.match(/\d{2,3}-\d{3,4}-\d{4}/g);

    if (phoneStr === undefined || phoneStr === null || phoneStr === '') {
      return originStr;
    }

    if (/-[0-9]{3}-/.test(phoneStr)) {
      result = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{3}-/g, '-***-'));
    } else if (/-[0-9]{4}-/.test(phoneStr)) {
      result = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{4}-/g, '-****-'));
    }

    return result;
  }
};
