import './RequestDetailContainer.scss';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import { CommonDateFormat } from '../../../service/common/model/enum/CommonDateFormat';
import { RequestInfo } from '../../../service/work/model/Work';
import WorkService from '../../../service/work/WorkService';

const RequestDetailContainer = () => {
  const history = useHistory();
  const workService = new WorkService();
  const { id } = useParams<{ id: string }>();
  const requestId = Number(id);

  const [request, setRequest] = useState<RequestInfo>();

  useEffect(() => {
    void workService.getRequestDetail(requestId).then((response) => {
      if (response.successOrNot === 'Y') {
        setRequest(response.data);
      } else {
        alert('의뢰요청내역 조회중 네트워크 장애가 발생하였습니다.\n잠시후 다시 시도해 주세요.');
      }
    });
  }, []); // eslint-disable-line

  const handleDeleteOnClick = () => {
    void workService.deleteRequest(requestId).then((response) => {
      if (response.successOrNot === 'Y') {
        history.push('/request');
      } else {
        alert('의뢰요청내역 삭제 처리중 네트워크 장애가 발생하였습니다.\n잠시후 다시 시도해 주세요.');
      }
    });
  };

  return (
    <div id="RequestDetailContainer">
      {request && (
        <div>
          <Row>
            <Form id="requestForm" style={{ width: '100%' }}>
              <Form.Row>
                <Form.Group as={Col} controlId="companyName">
                  <Form.Label>의뢰 회사명</Form.Label>
                  <Form.Control type="text" plaintext readOnly defaultValue={request.companyName} />
                </Form.Group>
                <Form.Group as={Col} controlId="companyName">
                  <Form.Label>요청날짜</Form.Label>
                  <Form.Control
                    type="text"
                    plaintext
                    readOnly
                    defaultValue={moment(request.requestDate).format(CommonDateFormat.DATETIME_HM)}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="teamName">
                  <Form.Label>요청자 팀명</Form.Label>
                  <Form.Control type="text" plaintext readOnly defaultValue={request.requesterTeam} />
                </Form.Group>
                <Form.Group as={Col} controlId="name">
                  <Form.Label>요청자명</Form.Label>
                  <Form.Control type="text" plaintext readOnly defaultValue={request.requesterName} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="title">
                  <Form.Label>요청명</Form.Label>
                  <Form.Control type="text" plaintext readOnly defaultValue={request.requestTitle} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="content">
                  <Form.Label>업무 상세 내용</Form.Label>
                  <div id="content">{request.requestContent}</div>
                </Form.Group>
              </Form.Row>
            </Form>
          </Row>
          <Row>
            <Button variant="secondary" onClick={handleDeleteOnClick}>
              삭제
            </Button>
            <Button
              variant="success"
              onClick={() => {
                history.push('/request/enroll/' + requestId);
              }}
            >
              수정
            </Button>
            <Button
              variant="success"
              onClick={() => {
                history.push('/request');
              }}
            >
              목록
            </Button>
          </Row>
        </div>
      )}
    </div>
  );
};

export default RequestDetailContainer;
