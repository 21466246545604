import React from 'react';

import BoardRegistForm from './component/BoardRegistForm';

function BoardRegistContainer() {
  return (
    <div id="BoardRegistContainer">
      <BoardRegistForm />
    </div>
  );
}

export default BoardRegistContainer;
