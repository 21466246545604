import './BoardRegistForm.scss';

import { MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import BoardService from '../../../service/board5/BoardService';
import { RegistBoardRequest } from '../../../service/board5/model/Board';
import CommonCodeService from '../../../service/common/CommonCodeService';
import { CommonCode, GroupCode } from '../../../service/common/model/CommonCode';

const BoardRegistForm: React.FC = (): ReactElement => {
  const [boardTitle, setBoardTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [contents, setContents] = useState('');
  const [categoryList, setCategoryList] = useState<CommonCode[]>([]);
  const history = useHistory();
  const commonService = new CommonCodeService();

  const boardService = new BoardService();

  useEffect(() => {
    const getCategoryList = async () => {
      return await commonService.getCommonCode(GroupCode.CATEGORY);
    };

    void getCategoryList().then((res) => {
      if (res && res.length > 0) {
        setSelectedCategory(res[0].codeId);
        setCategoryList(res);
      }
    });
  }, []);

  const handleTitleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBoardTitle(event.target.value);
  };

  const handleCategoryOnChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleContentsOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setContents(event.target.value);
  };

  const handleCancelBtnOnClick = () => {
    history.push('/board-5');
  };

  const handleSaveBtnOnClick = () => {
    const requestData: RegistBoardRequest = {
      boardTitle: boardTitle,
      category: selectedCategory,
      boardContents: contents,
    };

    void boardService.createBoardData(requestData).then((response) => {
      if (response.successOrNot === 'Y') {
        history.push('/board-5');
      }
    });
  };

  return (
    <div id="BoardRegistForm">
      <div className="title">
        <span id="title" data-testid="title">
          나도한마디
        </span>
        <span className="subTitle">등록하기</span>
        <Form className="inputForm">
          <Form.Row className="category-row">
            <Row>
              <Col className="title-col">
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="제목을 입력하세요."
                  id="inputTitle"
                  name="inputTitle"
                  data-testid="inputTitle"
                  className="inputTitle"
                  onChange={handleTitleOnChange}
                  value={boardTitle}
                  maxLength={40}
                />
              </Col>
              <Col className="categorySelect">
                <Select
                  className="category"
                  defaultValue=""
                  value={categoryList.length ? selectedCategory : ''}
                  onChange={handleCategoryOnChange}
                >
                  {categoryList.map((category) => {
                    return (
                      <MenuItem key={category.codeId} value={category.codeId}>
                        {category.codeName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </Form.Row>
          <Form.Row id="contents">
            <Form.Control as="textarea" onChange={handleContentsOnChange} maxLength={200} value={contents} rows={20} />
          </Form.Row>
          <div className="btnArea">
            <Button id="registBtn" type="button" onClick={handleSaveBtnOnClick}>
              <p>등록</p>
            </Button>
            <Button id="cancelBtn" type="button" onClick={handleCancelBtnOnClick}>
              <p>취소</p>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BoardRegistForm;
