import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { CommonDateFormat } from '../../../service/common/model/enum/CommonDateFormat';
import { RequestInfo } from '../../../service/work/model/Work';
import WorkService from '../../../service/work/WorkService';

const RequestListContainer = () => {
  const history = useHistory();
  const workService = new WorkService();

  const [request, setRequest] = useState<RequestInfo[]>([]);
  useEffect(() => {
    void workService.getRequestList().then((response) => {
      if (response.successOrNot === 'Y') {
        if (response.data.length === 0) {
          alert('조회된 요청내역이 없습니다.');
        } else {
          setRequest(response.data);
        }
      } else {
        alert('요청내역 조회중 네트워크 장애가 발생하였습니다.\n잠시후 다시 시도해 주세요.');
      }
    });
  }, []); // eslint-disable-line

  return (
    <div id="RequestListContainer">
      <Table bordered hover>
        <thead>
          <tr>
            <th>요청날짜</th>
            <th>요청자 팀명</th>
            <th>요청자명</th>
            <th>협력업체명</th>
            <th>요청명</th>
          </tr>
        </thead>
        <tbody>
          {request.map((value) => {
            return (
              <tr
                key={value.requestId}
                onClick={() => {
                  history.push('/request/detail/' + value.requestId);
                }}
              >
                <td>{moment(value.requestDate).format(CommonDateFormat.DATE_DISP)}</td>
                <td>{value.requesterTeam}</td>
                <td>{value.requesterName}</td>
                <td>{value.companyName}</td>
                <td>{value.requestTitle}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default RequestListContainer;
